import react, { useContext } from 'react';
import { GenderSelect } from '../../common/GenderSelect';
import { CategoriesSelect } from '../../common/CategoriesSelect';
import { IAdvertisement } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';


interface IAdvertisementEditProperties {
    advertisement: IAdvertisement;
    setAdvertisement: (a: IAdvertisement) => void;
}

export const AdvertisementEdit = ({ advertisement, setAdvertisement }: IAdvertisementEditProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    const categories: [string, string][] = advertisement.categories.map((c) => [c.split(' ')[0], c.split(' ')[1]]);
    const onCategories = (c: [string, string][]) => setAdvertisement({ ...advertisement, categories: c.map((c) => [c[0], c[1]].join(' ')) });

    const onDailyBudget = (v: string) => {
        const budget = Number(v);
        if (Number.isNaN(budget) || budget <= 0) {
            setAlertMessage('Daily budget must be greater than zero');
            return;
        }

        setAdvertisement({ ...advertisement, daily_budget: v});
    }

    const onClickCost = (v: string) => {
        const cost = Number(v);
        if (Number.isNaN(cost) || cost <= 0.10) {
            setAlertMessage('Click cost must be at least 0.10 (10 cents)');
            return;
        }

        setAdvertisement({ ...advertisement, click_cost: v});
    }

    return (
        <>
            <GenderSelect
                value={advertisement.gender}
                setGender={(g) => setAdvertisement({ ...advertisement, gender: g })}
            />
            <CategoriesSelect
                categories={categories}
                setCategories={onCategories}
            />
            <div className='data-general__instagram instagram top-animation'>
                <input
                    className='instagram__input'
                    type='text'
                    value={!!advertisement.instagram ? advertisement.instagram : ''}
                    onChange={(e) => setAdvertisement({ ...advertisement, instagram: e.target.value })}
                />
                <label>Instagram (optional)</label>
            </div>
            <div className='data-general__tiktok tiktok top-animation'>
                <input
                    className='tiktok__input'
                    type='text'
                    value={!!advertisement.tiktok ? advertisement.tiktok : ''}
                    onChange={(e) => setAdvertisement({ ...advertisement, tiktok: e.target.value })}
                />
                <label>Tiktok (optional)</label>
            </div>
            <div className='data-general__twitter twitter top-animation'>
                <input
                    className='twitter__input'
                    type='text'
                    value={!!advertisement.twitter ? advertisement.twitter : ''}
                    onChange={(e) => setAdvertisement({ ...advertisement, twitter: e.target.value })}
                />
                <label>Twitter (optional)</label>
            </div>
            <div className='data-general__daily-budget daily-budget top-animation'>
                <input
                    className='daily-budget__input'
                    type='number'
                    value={advertisement.daily_budget}
                    onChange={(e) => onDailyBudget(e.target.value)}
                />
                <label>Daily Budget</label>
            </div>
            <div className='data-general__click-cost click-cost top-animation'>
                <input
                    className='click-cost__input'
                    type='number'
                    value={advertisement.click_cost}
                    onChange={(e) => onClickCost(e.target.value)}
                />
                <label>Click Cost</label>
            </div>
        </>
    );
}