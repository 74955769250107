import react, { useState } from 'react';
import { IModer } from '../../../api/moder';
import { DeleteSvg } from '../../../static/svg/DeleteSvg';
import { CreateSvg } from '../../../static/svg/CreateSvg';
import { Paging } from '../../common/Paging';
import { dateToString } from '../../../libs/date';


interface IRootModerListItemProperties {
    moder: IModer;
    onDeleteRequest: (m: IModer) => void;
}

const RootModerListItem = ({ moder, onDeleteRequest }: IRootModerListItemProperties) => {
    const onDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onDeleteRequest(moder);
    }

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button className='actions__archive popup-link' onClick={onDelete}>
                    <DeleteSvg />
                </button>
            </div>
            <div className='table__item destination'>
                {moder.email}
            </div>
            <div className='table__item create-date'>
                {dateToString(moder.create_moment)}
            </div>
            <div className='table__item create-date'>
                {dateToString(moder.update_moment)}
            </div>
        </div>
    );
}


interface IRootModerListProperties {
    moderList: IModer[];
    onCreateRequest: () => void;
    onDeleteRequest: (m: IModer) => void;
}

export const RootModerList = ({ moderList, onCreateRequest, onDeleteRequest }: IRootModerListProperties) => {
    const pageSize = 10;

    const [page, setPage] = useState<number>(1);

    const list = moderList
        .sort((a, b) => b.create_moment.getTime() - a.create_moment.getTime())
        .slice((page - 1) * pageSize, page * pageSize);

    const onCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onCreateRequest();
    }

    return (
        <>
            <div className='advertisement-edit__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item destination'>Email</div>
                    <div className='table__item create-date'>Create</div>
                    <div className='table__item create-date'>Update</div>
                </div>
                <button
                    className='table__create-view create-btn popup-link'
                    onClick={onCreate}
                >
                    <CreateSvg />
                </button>
                {
                    list.map((m) => (
                        <RootModerListItem
                            key={m.id}
                            moder={m}
                            onDeleteRequest={onDeleteRequest}
                        />
                    ))
                }
            </div>
            <div className='advertisement-edit__control-row control-row'>
                <div className='control-row__body'>
                    <div className='control-row__paging paging'>
                        <Paging
                            page={page}
                            size={Math.ceil(moderList.length / pageSize)}
                            setPage={(p) => setPage(p)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}