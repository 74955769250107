import { server } from '.';


export interface ISupportChat {
    id: string;
    user_id: string;
    unread_root_message_number: number;
    unread_user_message_number: number;
    header: string;
    is_archived: boolean;
    create_moment: Date;
}

export interface ISupportChatMessage {
    chat_id: string;
    user_id: string;
    body: string;
    create_moment: Date;
}

export const getSupportChatList = async (bear: string): Promise<ISupportChat[]> => {
    const r = await fetch(
        `${server}/support/chat`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as ISupportChat[];

    res.forEach((chat) => {
        chat.unread_root_message_number = Number(chat.unread_root_message_number);
        chat.unread_user_message_number = Number(chat.unread_user_message_number);
        chat.create_moment = new Date(chat.create_moment);
    });

    return res;
}

export const getSupportMessageList = async (chatId: string, bear: string): Promise<ISupportChatMessage[]> => {
    const r = await fetch(
        `${server}/support/chat/${chatId}/message`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as ISupportChatMessage[];

    res.forEach((message) => {
        message.create_moment = new Date(message.create_moment);
    });

    return res;
}