import react, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../stor/AuthProvider';
import { RootUserTable } from './RootUserTable';
import { getStatUserList, IStatUser } from '../../../../api/stat';
import { AlertContext } from '../../../../stor/AlertProvider';


export const RootUserList = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [userList, setUserList] = useState<IStatUser[]>([]);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getStatUserList(user.bear);
                setUserList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    return (
        <section className='users-admin'>
            <div className='users-admin__container'>
                <div className='users-admin__title title'>
                    User List
                </div>
                <RootUserTable
                    statList={userList}
                />
            </div>
        </section>
    );
}