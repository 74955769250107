import react from 'react';
import { LinPlot } from '../../common/plot/LinPlot';
import { PiePlot } from '../../common/plot/PiePlot';
import { StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../api/stat';


export const RootTracking = () => {
    return (
        <>
            <section className='advertisement-view'>
                <div className='advertisement-view__container'>
                    <div className='advertisement__charts charts'>
                        <PiePlot
                            header={'Visits By Region'}
                            wait={false}
                            obj={StatPlotObject.NONE}
                            sub={StatPlotSubject.LINK}
                            pre={StatPlotPredicat.COUNT_BY_REGION}
                        />
                        <PiePlot
                            header={'Visits By Target'}
                            wait={false}
                            obj={StatPlotObject.NONE}
                            sub={StatPlotSubject.LINK}
                            pre={StatPlotPredicat.COUNT_BY_TARGET}
                        />
                    </div>
                    <div className='advertisement__charts charts'>
                        <LinPlot
                            header={'Clicks By Date'}
                            wait={false}
                            obj={StatPlotObject.NONE}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_MOMENT}
                        />
                        <PiePlot
                            header={'Clicks By Region'}
                            wait={false}
                            obj={StatPlotObject.NONE}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_REGION}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}