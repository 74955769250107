import { server } from '.';


export enum UserRole {
    CLIENT = 'CLIENT',
    MODER = 'MODER',
    ADMIN = 'ADMIN',
}

export interface IUser {
    bear: string;
    role: UserRole;
    email: string;
    balance: string;
}

export const signIn = async (email: string, password: string): Promise<IUser> => {
    const r = await fetch(
        `${server}/auth/sign-in`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const user: IUser = await r.json();
    return user;
}

export const signUpStart = async (email: string) => {
    const r = await fetch(
        `${server}/auth/sign-up/start`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message); 
    }
}

export const signUpClose = async (jwt: string, password: string): Promise<IUser> => {
    const r = await fetch(
        `${server}/auth/sign-up/close`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jwt, password }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const user: IUser = await r.json();
    return user;
}

export const signReStart = async (email: string) => {
    const r = await fetch(
        `${server}/auth/sign-re/start`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
}

export const signReClose = async (jwt: string, password: string): Promise<IUser> => {
    const r = await fetch(
        `${server}/auth/sign-re/close`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jwt, password }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const user: IUser = await r.json();
    return user;
}