import React from 'react';
import { IModel } from '../../../api/model';
import { GenderSelect } from '../GenderSelect';
import { CategoriesSelect } from '../CategoriesSelect';


interface IModelEditProperties {
    model: IModel;
    setModel: (model: IModel) => void;
}

export const ModelEdit = ({ model, setModel }: IModelEditProperties) => {
    return (
        <>
            <div className='data-create-view__name model-nickname top-animation'>
                <input
                    className='model-nickname__input'
                    type='text'
                    value={model.name}
                    onChange={(e) => setModel({ ...model, name: e.target.value })}
                />
                <label>Name</label>
            </div>
            <div className='data-create-view__about about top-animation'>
                <textarea
                    className='about__textarea'
                    cols={30}
                    rows={5}
                    value={model.about}
                    onChange={(e) => setModel({ ...model, about: e.target.value })}
                />
                <label>About</label>
            </div>
            <GenderSelect
                value={model.gender}
                setGender={(gender) => setModel({ ...model, gender: gender })}
            />
            <CategoriesSelect
                categories={model.categories.map((c) => {
                    const v = c.split(' ');
                    return [v[0], v[1]];
                })}
                setCategories={(categories) => {
                    const v = categories.map((c) => [c[0], c[1]].join(' '));
                    setModel({ ...model, categories: v });
                }}
            />
            <div className='data-create-view__twitter twitter top-animation'>
                <input
                    className='twitter__input'
                    type='text'
                    value={!!model.twitter ? model.twitter : ''}
                    onChange={(e) => setModel({ ...model, twitter: e.target.value })}
                />
                <label>Twitter (optional)</label>
            </div>
            <div className='data-create-view__tiktok tiktok top-animation'>
                <input
                    className='tiktok__input'
                    type='text'
                    value={!!model.tiktok ? model.tiktok : ''}
                    onChange={(e) => setModel({ ...model, tiktok: e.target.value })}
                />
                <label>Tiktok (optional)</label>
            </div>
            <div className='data-create-view__instagram instagram top-animation'>
                <input
                    className='instagram__input'
                    type='text'
                    value={!!model.instagram ? model.instagram : ''}
                    onChange={(e) => setModel({ ...model, instagram: e.target.value })}
                />
                <label>Instagram (optional)</label>
            </div>
        </>
    );
}