import react, { useContext, useEffect, useState } from 'react';
import { IWaitPayment, deleteWaitPayment, getPaymentStatus } from '../../../api/payment';
import { AuthContext } from '../../../stor/AuthProvider';
import { ClockSvg } from '../../../static/svg/ClockSvg';
import { AlertContext } from '../../../stor/AlertProvider';


interface IReadUserPaymentProperties {
    request: IWaitPayment;
    onChange: () => void;
    onCancel: () => void;
}

export const ReadUserPayment = ({request, onChange, onCancel}: IReadUserPaymentProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [leftTime, setLeftTime] = useState<string>('00:00');

    useEffect(() => {
        const work = async () => {
            try {
                const status = await getPaymentStatus(request.moment, user.bear);
                if (status != 'WAIT') {
                    onChange();
                }
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        const interval = setInterval(() => work(), 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const work = () => {
            const now = Date.now();

            if (request.expiration_timestamp <= now) {
                onChange();
                return;
            }

            const minute = Math.ceil((request.expiration_timestamp - now) / 60 / 1000) % 60;
            const second = Math.ceil((request.expiration_timestamp - now) / 1000) % 60;
            setLeftTime(`${9 < minute ? minute : `0${minute}`}:${9 < second ? second : `0${second}`}`);
        }

        const interval = setInterval(() => work(), 1000);

        return () => clearInterval(interval);
    }, []);

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        try {
            await deleteWaitPayment(user.bear);
            onCancel();
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }

    return (
        <div className='funds__additional-payment additional-payment active'>
            <h3 className='additional-payment__title title__small'>
                Current payment request
            </h3>
            <div className='additional-payment__add-data add-data'>
                <div className='add-data__info info-data'>
                    <div className='info-data__time-money'>
                        <div className='info-data__time'>
                            <ClockSvg />
                            {leftTime}
                        </div>
                        <div className='info-data__amount-usd'>
                            <span>
                                {request.amount} $
                            </span>
                        </div>
                    </div>
                    <div className='info-data__amount top-animation'>
                        <input disabled type='text' value={request.amount}/>
                        <label>
                            USDT amount
                        </label>
                    </div>                    
                    <div className='info-data__to-wallet top-animation'>
                        <input disabled type='text' value={request.recipient_address}/>
                        <label>
                            Recipient address
                        </label>
                    </div>
                </div>
                <div className='add-data__qr qr-code'>
                    <img className='qr-code__img' src={request.recipient_qr_code_url} />
                </div>
            </div>
            <div className='additional-payment__buttons buttons'>
                <button className='buttons__cancel' onClick={onSubmit}>
                    Cancel
                </button>
            </div>            
        </div>
    );
}