import { WarningSvg } from '../../../static/svg/WarningSvg';
import { AvatarSvg } from '../../../static/svg/AvatarSvg';
import react, { useContext, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { checkImageFormat } from '../../../libs/file';
import { IAdvertisementModel } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';


interface IAdvertisementModelEditProperties {
    model: IAdvertisementModel;
    setModel: (m: IAdvertisementModel) => void;
    onSubmit: () => void;
}

export const AdvertisementModelEdit = ({ model, setModel, onSubmit }: IAdvertisementModelEditProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [onSubmit]);

    const onFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const work = async () => {
            let avataraFile: File = e.target.files[0];

            const errorMessage = await checkImageFormat(avataraFile);
            if (!!errorMessage) {
                setAlertMessage(errorMessage);
                return;
            }

            avataraFile = await imageCompression(avataraFile, { maxSizeMB: 0.25 });

            const avataraUrl = URL.createObjectURL(avataraFile);

            setModel({ ...model, avatar: avataraUrl, avatar_file: avataraFile });
        }

        work();
    }

    return (
        <div className='create-view__data data-create-view'>
            <div className='data-create-view__body'>
                <div className='data-create-view__warninng'>
                    <WarningSvg />
                    Do not upload images that show any sexually explicit content (genitals, nipples, etc.)
                </div>
                <label className='data-create-view__photo-upload'>
                    <input
                        type='file'
                        accept='image/*'
                        onChange={onFile}
                    />
                    <AvatarSvg />
                    Avatar
                </label>
                <div className='data-create-view__name model-nickname top-animation'>
                    <input
                        className='model-nickname__input'
                        type='text'
                        value={model.name}
                        onChange={(e) => setModel({ ...model, name: e.target.value })}
                    />
                    <label>
                        Name
                    </label>
                </div>
                <div className='data-create-view__dectination-url dectination-url top-animation'>
                    <input
                        className='dectination-url__input'
                        type='text'
                        value={model.destination_url}
                        onChange={(e) => setModel({ ...model, destination_url: e.target.value })}
                    />
                    <label>
                        Destination URL
                    </label>
                </div>
                <div className='data-create-view__about about top-animation'>
                    <textarea
                        className='about__textarea'
                        rows={5}
                        cols={30}
                        value={model.about}
                        onChange={(e) => setModel({ ...model, about: e.target.value })}
                    />
                    <label>
                        About
                    </label>
                </div>
                <button
                    className='create-view__create'
                    onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}