import react, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../../stor/AuthProvider';
import { AdvertisementItemReadStat } from './AdvertisementItemReadStat';
import { AdvertisementItemReadTable } from './AdvertisementItemReadTable';
import { ReadAdvertisementModelWindow } from '../../common/ReadAdvertisementModelWindow';
import { IAdvertisement, IAdvertisementModel, getAdvertisement } from '../../../../api/advertisement';
import { LinPlot } from '../../../common/plot/LinPlot';
import { PiePlot } from '../../../common/plot/PiePlot';
import { StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../../api/stat';
import { AlertContext } from '../../../../stor/AlertProvider';


export const AdvertisementItemRead = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const [searchParams, _] = useSearchParams();

    const { user } = useContext(AuthContext);

    const [advertisement, setAvertisement] = useState<IAdvertisement>(null);

    const [model, setModel] = useState<IAdvertisementModel>(null);

    useEffect(() => {
        const work = async () => {
            try {
                const nickname = searchParams.get('nickname');

                const r = await getAdvertisement(nickname, user.bear);

                setAvertisement(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    return (
        <>
            <section className='advertisement-view'>
                <div className='advertisement-view__container'>
                    <h2 className='advertisement-view__title title'>Advertisement</h2>
                    {
                        !!advertisement &&
                        <AdvertisementItemReadStat item={advertisement} />
                    }
                    <div className='advertisement__charts charts'>
                        <LinPlot
                            header={'Clicks By Date'}
                            wait={!advertisement}
                            obj={StatPlotObject.AD}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_MOMENT}
                            objId={!!advertisement ? advertisement.nickname : undefined}
                        />
                        <PiePlot
                            header={'Clicks By Region'}
                            wait={!advertisement}
                            obj={StatPlotObject.AD}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_REGION}
                            objId={!!advertisement ? advertisement.nickname : undefined}
                        />
                    </div>
                    {
                        !!advertisement &&
                        <AdvertisementItemReadTable
                            item={advertisement}
                            onReadRequest={(m) => setModel(m)}
                        />
                    }
                </div>
            </section>
            {
                !!model &&
                <ReadAdvertisementModelWindow
                    advertisement={advertisement}
                    model={model}
                    onExit={() => setModel(null)}
                />
            }
        </>
    );
}