import react from 'react';
import { dateToString } from '../../../libs/date';


interface IPlotIntervalFormProperties {
    from?: Date;
    to?: Date;
    setFrom: (f: Date) => void;
    setTo: (f: Date) => void;
}

export const PlotIntervalForm = ({ from, to, setFrom, setTo }: IPlotIntervalFormProperties) => {
    return (
        <div className='charts__date-picker date-picker'>
            <input
                className='date-picker__start'
                placeholder='dd/mm/yyyy'
                type='date'
                max={!!to ? dateToString(new Date(to.getTime() - 24 * 60 * 60 * 1000)).split(' ')[0] : undefined}
                value={!!from ? dateToString(from).split(' ')[0] : undefined}
                onChange={(e) => {
                    if (!from) {
                        return;
                    }

                    setFrom(new Date(e.target.value));
                }}
            />
            <input
                className='date-picker__end'
                placeholder='dd/mm/yyyy'
                type='date'
                min={!!from ? dateToString(new Date(from.getTime() + 24 * 60 * 60 * 1000)).split(' ')[0] : undefined}
                value={!!to ? dateToString(to).split(' ')[0] : undefined}
                onChange={(e) => {
                    if (!to) {
                        return;
                    }

                    setTo(new Date(e.target.value));
                }}
            />
        </div>
    );
}