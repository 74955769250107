import React from 'react';


interface IPagingProperties {
    size: number;
    page: number;
    setPage: (page: number) => void;
}

export const Paging = ({ size, page, setPage }: IPagingProperties) => {
    return (
        <>
            <button
                className={`paging__left-arrow ${(size == 0 || page == 1) ? 'paging__disabled' : ''}`}
                onClick={(e) => {
                    e.preventDefault();

                    if (size == 0 || page == 1) {
                        return;
                    }

                    setPage(page - 1);
                }}
            >
                <svg viewBox='-4 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' fill='#000000'>
                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                    <g id='SVGRepo_iconCarrier'>
                        <g id='Free-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                            <g transform='translate(-825.000000, -674.000000)' id='Group' stroke='#000000' strokeWidth='2'>
                                <g transform='translate(819.000000, 672.000000)' id='Shape'>
                                    <polyline points='17.0011615 3 7 12.0021033 17.0011615 21.0042067' />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <button
                className={`paging__right-arrow ${(size == 0 || size == page) ? 'paging__disabled' : ''}`}
                onClick={(e) => {
                    e.preventDefault();

                    console.log(size, page)

                    if (size == 0 || size == page) {
                        return;
                    }

                    setPage(page + 1);
                }}
            >
                <svg viewBox='-4 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' fill='#000000'>
                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                    <g id='SVGRepo_iconCarrier'>
                        <g id='Free-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                            <g transform='translate(-751.000000, -674.000000)' id='Group' stroke='#000000' strokeWidth='2'>
                                <g transform='translate(745.000000, 672.000000)' id='Shape'>
                                    <polyline points='7 3 17.0011615 12.0021033 7 21.0042067' />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </>
    );
}