import { server } from '.';
import { UserRole } from './auth';


export interface IModer {
    id: string;
    role: UserRole;
    email: string;
    password_hash: string;
    balance: number;
	create_moment: Date;
    update_moment: Date;
}

const up = (moder: IModer) => {
    moder.create_moment = new Date(moder.create_moment);
    moder.update_moment = new Date(moder.update_moment);
}

export const createModer = async (email: string, password: string, bear: string): Promise<IModer> => {
    const r = await fetch(
        `${server}/moder`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
            body: JSON.stringify({ email, password }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
    
    const data: IModer = await r.json();
    up(data);
    return data;
}

export const getModerList = async (bear: string): Promise<IModer[]> => {
    const r = await fetch(
        `${server}/moder`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            }
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
    
    const data: IModer[] = await r.json();
    data.forEach((m) => up(m));
    return data;
}

export const deleteModer = async (id: string, bear: string): Promise<IModer> => {
    const r = await fetch(
        `${server}/moder/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            }
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
    
    const data: IModer = await r.json();
    up(data);
    return data;
}