import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../../stor/AuthProvider';
import { createSyntheticPayment, ISuccessPayment } from '../../../../api/payment';
import { AlertContext } from '../../../../stor/AlertProvider';
import { CloseSvg } from '../../../../static/svg/CloseSvg';


interface IRootUserItemCreateBalanceIncreaseWindowProperties {
    userId: string;
    onCreate: (payment: ISuccessPayment) => void;
    onExit: () => void;
}

export const RootUserItemCreateBalanceIncreaseWindow = ({ userId, onCreate, onExit }: IRootUserItemCreateBalanceIncreaseWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [amount, setAmount] = useState<number>(0);

    const onSubmit = useCallback(async () => {
        try {
            if (!Number.isInteger(Number(amount)) || Number(amount) <= 0) {
                setAlertMessage('Amount must be positive integer');
                return;
            }

            const r = await createSyntheticPayment(userId, amount, user.bear);
            onCreate(r);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [userId, amount, onCreate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='add-transaction-admin popup popup-active'>
            <div className='add-transaction-admin__body popup__body' ref={ref}>
                <h3 className='add-transaction-admin__title popup__title title__small'>
                    Increase User Balance
                </h3>
                <div
                    className='add-transaction-admin__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='add-transaction-admin__wrapper popup__wrapper'>
                    <div className='add-transaction-admin__input top-animation'>
                        <input
                            type='number'
                            value={amount}
                            onChange={(e) => setAmount(Number(e.target.value))}
                        />
                        <label>
                            Increase Amount
                        </label>
                    </div>
                </div>
                <div className='add-transaction-admin__buttons popup__buttons'>
                    <button
                        className='add-transaction-admin__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}