import { ReadSvg } from '../../../../static/svg/ReadSvg';
import { UpdateSvg } from '../../../../static/svg/UpdateSvg';
import react, { useContext, useState } from 'react';
import { AuthContext } from '../../../../stor/AuthProvider';
import { Paging } from '../../../common/Paging';
import { UserRole } from '../../../../api/auth';
import { ISupportChat } from '../../../../api/support';
import { dateToString } from '../../../../libs/date';


interface IRootUserItemSupportChatTableItemProperties {
    chat: ISupportChat;
    onReadRequest: (chatId: string) => void;
    onUpdateRequest: (chatId: string) => void;
}

const RootUserItemSupportChatTableItem = ({
    chat,
    onReadRequest,
    onUpdateRequest,
}: IRootUserItemSupportChatTableItemProperties) => {
    const { user } = useContext(AuthContext);

    const openMessageCount = user.role == UserRole.CLIENT ? chat.unread_root_message_number : chat.unread_user_message_number;

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                {
                    !!chat.is_archived &&
                    <button className='actions__archive' onClick={(e) => { e.preventDefault(); onReadRequest(chat.id) }}>
                        <ReadSvg />
                    </button>
                }
                {
                    !chat.is_archived &&
                    <button className='actions__archive' onClick={(e) => { e.preventDefault(); onUpdateRequest(chat.id) }}>
                        <UpdateSvg />
                    </button>
                }
            </div>
            <div className='table__item chat-date-creation'>
                {dateToString(chat.create_moment)}
            </div>
            <div className='table__item subject'>
                {chat.header}
            </div>
            <div className='table__item new-massage'>
                {0 < openMessageCount && <span>{openMessageCount}</span>}
            </div>
        </div>
    );
}


interface IRootUserItemSupportChatTableProperties {
    chatList: ISupportChat[];
    onReadRequest: (chatId: string) => void;
    onUpdateRequest: (chatId: string) => void;
}

export const RootUserItemSupportChatTable = ({
    chatList,
    onReadRequest,
    onUpdateRequest,
}: IRootUserItemSupportChatTableProperties) => {
    const { user } = useContext(AuthContext);

    const pageSize = 3;

    const [page, setPage] = useState<number>(1);

    const chatListSlice = chatList
        .sort((a, b) => {
            if (!a.is_archived && b.is_archived) {
                return -1;
            }
            if (a.is_archived && !b.is_archived) {
                return +1;
            }
            if (user.role == UserRole.CLIENT && a.unread_root_message_number != b.unread_root_message_number) {
                return b.unread_root_message_number - a.unread_root_message_number;
            }
            if (user.role != UserRole.CLIENT && a.unread_user_message_number != b.unread_user_message_number) {
                return b.unread_user_message_number - a.unread_user_message_number;
            }

            return b.create_moment.getTime() - a.create_moment.getTime();
        })
        .slice((page - 1) * pageSize, page * pageSize);

    return (
        <div className='support__table table'>
            <div className='table__row row-table-first'>
                <div className='table__item actions'>Actions</div>
                <div className='table__item chat-date-creation'>Date</div>
                <div className='table__item subject'>Header</div>
                <div className='table__item new-massage'>Unread</div>
            </div>
            {
                chatListSlice
                    .map((chat) => (
                        <RootUserItemSupportChatTableItem
                            key={chat.id}
                            chat={chat}
                            onReadRequest={onReadRequest}
                            onUpdateRequest={onUpdateRequest}
                        />
                    ))
            }
            <div className='support__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(chatList.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </div>
    );
}