import react, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { IModer, getModerList } from '../../../api/moder';
import { RootModerList } from './RootModerList';
import { CreateRootModerWindow } from './CreateRootModerWindow';
import { DeleteRootModerWindow } from './DeleteRootModerWindow';
import { AlertContext } from '../../../stor/AlertProvider';


interface IRequest {
    method: 'CREATE' | 'DELETE';
    moder?: IModer;
}

export const RootModer = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [moderList, setModerList] = useState<IModer[]>([]);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getModerList(user.bear);
                setModerList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    const [request, setRequest] = useState<IRequest>(null);

    return (
        <>
            <section className='advertisement-view'>
                <div className='advertisement-view__container'>
                    <h2 className='advertisement-view__title title'>Moder List</h2>
                    <RootModerList
                        moderList={moderList}
                        onCreateRequest={() => setRequest({ method: 'CREATE' })}
                        onDeleteRequest={(m) => { setRequest({ method: 'DELETE', moder: m }) }}
                    />
                </div>
            </section>
            {
                !!request && request.method == 'CREATE' &&
                <CreateRootModerWindow
                    onSuccess={(m) => { setRequest(null); setModerList([m, ...moderList]); }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'DELETE' &&
                <DeleteRootModerWindow
                    moder={request.moder}
                    onSuccess={(m) => { setRequest(null); setModerList(moderList.filter((mm) => mm.id != m.id)) }}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}