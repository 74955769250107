import react, { useCallback, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { IAdvertisement, IUpdateAdvertisementDTO, updateAdvertisement } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ISwitchAdvertisementWindowProperties {
    advertisement: IAdvertisement;
    onSwitch: (a: IAdvertisement) => void;
    onExit: () => void;
}

export const SwitchAdvertisementWindow = ({ advertisement, onSwitch, onExit }: ISwitchAdvertisementWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const onSubmit = useCallback(async () => {
        try {
            const dto: IUpdateAdvertisementDTO = {
                nickname: advertisement.nickname,
                gender: advertisement.gender,
                categories: advertisement.categories,
                daily_budget: Number(advertisement.daily_budget),
                click_cost: Number(advertisement.click_cost),
                is_active: !advertisement.is_active,
                is_delete: advertisement.is_delete,
                create_model_list: [],
                update_model_list: [],
            };

            ['instagram', 'tiktok', 'twitter'].forEach((key) => {
                if (!!advertisement[key]) {
                    dto[key] = advertisement[key];
                }
            });

            const a = await updateAdvertisement(dto, user.bear);

            onSwitch(a);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [advertisement, onSwitch]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='archive-popup popup popup-active'>
            <div className='archive-popup__body popup__body' ref={ref}>
                <div className='archive-popup__title popup__title title__small'>
                    Switch Advertisement
                </div>
                <div
                    className='archive-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='archive-popup__wrapper popup__wrapper'>
                    <p className='archive-popup__text'>
                        Are you sure?
                    </p>
                </div>
                <div className='archive-popup__buttons popup__buttons'>
                    <button
                        className='archive-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}