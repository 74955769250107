import { server } from '.';


export interface ISpam {
    recipient?: string;
    header: string;
    create_moment: Date;
}

export const createSpam = async (recipient_list: string[], header: string, body: string, bear: string): Promise<ISpam> => {
    const r = await fetch(
        `${server}/spam`,
        {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${bear}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ recipient_list, header, body }),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const spam = await r.json() as ISpam;
    spam.create_moment = new Date(spam.create_moment);
    return spam;
}

export const getSpamList = async (bear: string): Promise<ISpam[]> => {
    const r = await fetch(
        `${server}/spam`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const spamList = await r.json() as ISpam[];
    spamList.forEach((spam) => spam.create_moment = new Date(spam.create_moment));
    return spamList;
}

export const getSpamPossibleRecipientList = async (bear: string): Promise<string[]> => {
    const r = await fetch(
        `${server}/spam/possible-recipient`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const possibleRecipientList = await r.json() as string[];
    return possibleRecipientList;
}