import React from 'react';

export const ReadSvg = () => {
    return (
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <circle cx='12' cy='12' r='3.5' stroke='#000000' />
                <path d='M21 12C21 12 20 4 12 4C4 4 3 12 3 12' stroke='#000000' />
            </g>
        </svg>
    );
}