import react, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../../stor/AuthProvider';
import { RootUserItemSupportChatTable } from './RootUserItemSupportChatTable';
import { RootUserItemSpamTable } from './RootUserItemSpamTable';
import { RootUserItemPaymentTable } from './RootUserItemPaymentTable';
import { RootUserItemCreateBalanceIncreaseWindow } from './RootUserItemCreateBalanceIncreaseWindow';
import { SupportChatWindow } from '../../../common/support/SupportChatWindow';
import { SpamCreateWindow } from '../../../common/spam/SpamCreateWindow';
import { UserRole } from '../../../../api/auth';
import { ISupportChat } from '../../../../api/support';
import { getStatUser, IStatUserFull, StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../../api/stat';
import { LinPlot } from '../../../common/plot/LinPlot';
import { DepositLByDate } from '../../../common/plot/DepositLByDate';
import { AlertContext } from '../../../../stor/AlertProvider';
import { SupportContext } from '../../../../stor/SupportProvider';


interface IRequest {
    kind: 'INCREASE_BALANCE' | 'READ_CHAT' | 'UPDATE_CHAT' | 'CREATE_MAIL';
    chat?: ISupportChat;
}

export const RootUserItem = () => {
    const [searchParams, ssp] = useSearchParams();

    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const { supportData } = useContext(SupportContext);

    const [stat, setStat] = useState<IStatUserFull | null>(null);

    const [request, setRequest] = useState<IRequest | null>(null);

    useEffect(() => {
        const work = async () => {
            try {
                const userId = searchParams.get('user_id');
                if (!userId) {
                    throw new Error('Query param [user_id] must be.');
                }

                const r = await getStatUser(userId, user.bear);

                setStat(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    useEffect(() => {
        if (!stat) {
            return;
        }

        if (!!supportData) {
            setStat({...stat, chat_list: supportData.chatList, chat_number: supportData.chatList.length});
        }
        else {
            setStat({...stat, chat_list: [], chat_number: 0});
        }

    }, [supportData])

    return (
        <>
            <section className='about-user'>
                <div className='about-user__container'>
                    <div className='about-user__title title'>
                        {!!stat && `${stat.email} ${stat.region}`}
                    </div>
                    <div className='about-user__charts charts'>
                        <DepositLByDate
                            depositList={!!stat ? stat.transaction_list : []}
                        />
                        <LinPlot
                            header={'Withdraws By Count'}
                            wait={!stat}
                            obj={StatPlotObject.USER}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.SPENT_BY_MOMENT}
                            objId={!!stat ? stat.id : undefined}
                        />
                    </div>
                    {
                        !!stat && user.role == UserRole.ADMIN &&
                        <button
                            className='about-user__add-funds'
                            onClick={(e) => { e.preventDefault(); setRequest({ kind: 'INCREASE_BALANCE' }); }}
                        >
                            Increase user balance
                        </button>
                    }
                    <RootUserItemPaymentTable
                        paymentList={!!stat ? stat.transaction_list : []}
                    />
                    <RootUserItemSupportChatTable
                        chatList={!!stat ? stat.chat_list : []}
                        onReadRequest={(chatId) => setRequest({
                            kind: 'READ_CHAT',
                            chat: stat.chat_list.find((chat) => chat.id == chatId),
                        })}
                        onUpdateRequest={(chatId) => setRequest({
                            kind: 'UPDATE_CHAT',
                            chat: stat.chat_list.find((chat) => chat.id == chatId),
                        })}
                    />
                    <RootUserItemSpamTable
                        spamList={!!stat ? stat.mail_list : []}
                        onCreateRequest={() => setRequest({ kind: 'CREATE_MAIL' })}
                    />
                </div>
            </section>
            {
                !!request && request.kind == 'INCREASE_BALANCE' &&
                <RootUserItemCreateBalanceIncreaseWindow
                    userId={stat.id}
                    onCreate={(tr) => {
                        setStat({ ...stat, transaction_list: [tr, ...stat.transaction_list] });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'READ_CHAT' &&
                <SupportChatWindow
                    createFlag={false}
                    chat={request.chat}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'UPDATE_CHAT' &&
                <SupportChatWindow
                    createFlag={true}
                    chat={request.chat}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'CREATE_MAIL' &&
                <SpamCreateWindow
                    recipientList={[stat.email]}
                    onCreate={(mail) => {
                        setStat({ ...stat, mail_list: [mail, ...stat.mail_list] });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}