import react, { useCallback, useContext, useEffect, useState } from 'react';
import { IWaitPayment, createWaitPayment } from '../../../api/payment';
import { AuthContext } from '../../../stor/AuthProvider';
import { WarningSvg } from '../../../static/svg/WarningSvg';
import { AlertContext } from '../../../stor/AlertProvider';


interface ICreateUserPaymentProperties {
    onCreate: (request: IWaitPayment) => void;
}

export const CreateUserPayment = ({ onCreate }: ICreateUserPaymentProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [amount, setAmount] = useState<string>();

    const onSubmit = useCallback(async () => {
        try {
            if (!Number.isInteger(Number(amount)) || Number(amount) <= 0) {
                setAlertMessage('Amount must be positive integer');
                return;
            }

            const request = await createWaitPayment(Number(amount), user.bear);
            onCreate(request);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [amount, onCreate]);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [onSubmit]);

    return (
        <div className='funds__payment payment'>
            <h3 className='payment__title title__small'>
                Create payment request
            </h3>
            <div className='payment__initial-data initial-data'>
                <div className='initial-data__body'>
                    <div className='initial-data__amount amount top-animation'>
                        <input
                            className='amount__input'
                            type='number'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <label>
                            Amount
                        </label>
                    </div>
                    <div className='initial-data__info'>
                        <WarningSvg />
                        <span>
                            We can add to your amount up to 99 cents for identification.
                            <br />
                            Cents will be included to general sum.
                        </span>
                    </div>
                    <button
                        className='initial-data__btn'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}