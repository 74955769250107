import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AdvertisementModelView } from './AdvertisementModelView';
import { AdvertisementModelEdit } from './AdvertisementModelEdit';
import { IAdvertisement, IAdvertisementModel, upAdvertisementModelByEdit } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface IUpdateAdvertisementModelWindowProperties {
    advertisement: IAdvertisement;
    model: IAdvertisementModel;
    onUpdate: (m: IAdvertisementModel) => void;
    onExit: () => void;
}

export const UpdateAdvertisementModelWindow = ({
    advertisement,
    model,
    onUpdate,
    onExit
}: IUpdateAdvertisementModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const [curr, setCurr] = useState<IAdvertisementModel>(model);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);

        return () => {
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage]);

    const onSubmit = useCallback(() => {
        try {
            const m = upAdvertisementModelByEdit(advertisement.nickname, curr);
            onUpdate(m);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [advertisement, curr, onUpdate]);

    return (
        <div className='create-view__popup popup popup-active'>
            <div className='create-view__body popup__body' ref={ref}>
                <h3 className='create-view__title popup__title title__small'>
                    Update Advertisement Model
                </h3>
                <div
                    className='create-view__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-view__wrapper popup__wrapper'>
                    <div className='create-view__presentation presentation'>
                        <AdvertisementModelView
                            advertisement={advertisement}
                            model={curr}
                        />
                    </div>
                    <AdvertisementModelEdit
                        model={curr}
                        setModel={(m) => setCurr(m)}
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </div>
    );
}