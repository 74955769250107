import react, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../stor/AuthProvider';
import { UserRole } from '../../../../api/auth';
import { IAdvertisement, IUpdateAdvertisementDTO, ICreateAdvertisementModelDTO, IUpdateAdvertisementModelDTO, updateAdvertisement } from '../../../../api/advertisement';
import { AlertContext } from '../../../../stor/AlertProvider';
import { CloseSvg } from '../../../../static/svg/CloseSvg';


interface IUpdateAdvertisementItemUpdateWindowProperties {
    prev: IAdvertisement;
    curr: IAdvertisement;
    onExit: () => void;
}

export const UpdateAdvertisementItemUpdateWindow = ({
    prev,
    curr,
    onExit
}: IUpdateAdvertisementItemUpdateWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const onSubmit = useCallback(async () => {
        try {
            const dto: IUpdateAdvertisementDTO = {
                nickname: curr.nickname,
                gender: curr.gender,
                categories: curr.categories,
                daily_budget: Number(curr.daily_budget),
                click_cost: Number(curr.click_cost),
                is_active: curr.is_active,
                is_delete: curr.is_delete,
                create_model_list: [],
                update_model_list: [],
            };

            ['instagram', 'tiktok', 'twitter'].forEach((key) => {
                if (!!curr[key]) {
                    dto[key] = curr[key];
                }
            });

            const cml = curr.model_list.filter((cm) => prev.model_list.every((pm) => pm.id != cm.id));
            for (const m of cml) {
                if (!m.avatar_file) {
                    console.error('!m.avatar_file');
                    setAlertMessage('Internal error');
                    return;
                }

                const d: ICreateAdvertisementModelDTO = {
                    avatar: m.avatar_file,
                    name: m.name,
                    about: m.about,
                };

                ['apath', 'trial'].forEach((key) => {
                    if (!!m[key]) {
                        d[key] = m[key];
                    }
                });

                dto.create_model_list.push(d);
            }

            const uml = curr.model_list.filter((cm) => prev.model_list.some((pm) => pm.id == cm.id && pm.update_moment.getTime() != cm.update_moment.getTime()));
            for (const m of uml) {
                const d: IUpdateAdvertisementModelDTO = {
                    id: m.id,
                    name: m.name,
                    about: m.about,
                    is_active: m.is_active,
                    is_delete: m.is_delete,
                };

                if (!!m.avatar_file) {
                    d.avatar = m.avatar_file;
                }

                ['apath', 'trial'].forEach((key) => {
                    if (!!m[key]) {
                        d[key] = m[key];
                    }
                });

                dto.update_model_list.push(d);
            }

            await updateAdvertisement(dto, user.bear);

            navigate(`/${user.role == UserRole.CLIENT ? 'user' : 'root'}/advertisement`);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [prev, curr]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='archive-popup popup popup-active'>
            <div className='archive-popup__body popup__body' ref={ref}>
                <h3 className='archive-popup__title popup__title title__small'>
                    Update Advertisement
                </h3>
                <div
                    className='archive-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='archive-popup__wrapper popup__wrapper'>
                    <p className='archive-popup__text'>
                        Are you shure?
                    </p>
                </div>
                <div className='archive-popup__buttons popup__buttons'>
                    <button
                        className='archive-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}