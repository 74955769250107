import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AdvertisementModelView } from './AdvertisementModelView';
import { AdvertisementModelEdit } from './AdvertisementModelEdit';
import { IAdvertisement, IAdvertisementModel, upAdvertisementModelByCreate, upAdvertisementModelByEdit } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ICreateAdvertisementModelWindowProperties {
    advertisement: IAdvertisement;
    onCreate: (m: IAdvertisementModel) => void;
    onExit: () => void;
}

export const CreateAdvertisementModelWindow = ({
    advertisement,
    onCreate,
    onExit
}: ICreateAdvertisementModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const [model, setModel] = useState<IAdvertisementModel>(null);

    useEffect(() => {
        const work = async () => {
            try {
                const m = await upAdvertisementModelByCreate(advertisement);
                setModel(m);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);

        return () => {
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage]);

    const onSubmit = useCallback(() => {
        try {
            const m = upAdvertisementModelByEdit(advertisement.nickname, model);
            onCreate(m);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [advertisement, model, onCreate]);

    return (
        <div className='create-view__popup popup popup-active'>
            <div className='create-view__body popup__body' ref={ref}>
                <h3 className='create-view__title popup__title title__small'>
                    Create Advertisement Model
                </h3>
                <div
                    className='create-view__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-view__wrapper popup__wrapper'>
                    {
                        !!model &&
                        <div className='create-view__presentation presentation'>
                            <AdvertisementModelView
                                advertisement={advertisement}
                                model={model}
                            />
                        </div>
                    }
                    {
                        !!model &&
                        <AdvertisementModelEdit
                            model={model}
                            setModel={(m) => setModel(m)}
                            onSubmit={onSubmit}
                        />
                    }
                </div>
            </div>
        </div>
    );
}