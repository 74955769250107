import './App.scss';
import React, { useContext } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { UserRole } from './api/auth';
import { AuthContext, AuthProvider } from './stor/AuthProvider';
import { SignIn } from './component/auth/SignIn';
import { RootNavigation } from './component/root/navigation';
import { RootModel } from './component/root/model';
import { UserPayment } from './component/user/payment';
import { UserNavigation } from './component/user/navigation';
import { RootModer } from './component/root/moder';
import { RootModeration } from './component/root/moderation';
import { RootTracking } from './component/root/tracking';
import { RootSupport } from './component/root/support';
import { UserSupport } from './component/user/support';
import { SignUpStart } from './component/auth/SignUpStart';
import { SignUpClose } from './component/auth/SignUpClose';
import { SignReStart } from './component/auth/SignReStart';
import { SignReClose } from './component/auth/SignReClose';
import { RootSpam } from './component/root/spam';
import { RootUserList } from './component/root/user/list';
import { RootUserItem } from './component/root/user/item';
import { AdvertisementList } from './component/advertisement/list';
import { AdvertisementItemCreate } from './component/advertisement/item/create';
import { AdvertisementItemRead } from './component/advertisement/item/read';
import { AdvertisementItemUpdate } from './component/advertisement/item/update';
import { NotFound } from './component/common/NotFound';
import { AlertContext, AlertProvider } from './stor/AlertProvider';
import { AlertWindow } from './component/common/AlertWindow';
import { SupportProvider } from './stor/SupportProvider';


const router = createBrowserRouter([
    {
        path: '/',
        Component: () => {
            const { user } = useContext(AuthContext);

            if (!user) {
                return <Navigate to='/auth/sign-in' />;
            }

            switch (user.role) {
                case UserRole.CLIENT:
                    return <Navigate to='/user/advertisement' />;
                case UserRole.MODER:
                case UserRole.ADMIN:
                    return <Navigate to='/root/model' />;
                default:
                    return null;
            }
        },
    },
    {
        path: '/auth',
        Component: () => {
            const { alertMessage } = useContext(AlertContext);

            const { user } = useContext(AuthContext);

            if (!!user) {
                return <Navigate to='/' />;
            }

            return (
                <div className='wrapper'>
                    <main className='page'>
                        <Outlet />
                        {
                            0 < alertMessage.length &&
                            <AlertWindow />
                        }
                    </main>
                </div>
            );
        },
        children: [
            {
                path: 'sign-in',
                Component: SignIn,
            },
            {
                path: 'sign-up/start',
                Component: SignUpStart,
            },
            {
                path: 'sign-up/close',
                Component: SignUpClose,
            },
            {
                path: 'sign-re/start',
                Component: SignReStart,
            },
            {
                path: 'sign-re/close',
                Component: SignReClose,
            },
        ],
    },
    {
        path: '/root',
        Component: () => {
            const { alertMessage } = useContext(AlertContext);

            const { user } = useContext(AuthContext);

            if (!user || ![UserRole.ADMIN, UserRole.MODER].includes(user.role)) {
                return <Navigate to='/' />;
            }

            return (
                <div className='wrapper'>
                    <RootNavigation />
                    <main className='page'>
                        <Outlet />
                        {
                            0 < alertMessage.length &&
                            <AlertWindow />
                        }
                    </main>
                </div>
            );
        },
        children: [
            {
                path: 'advertisement',
                Component: () => {
                    const { user } = useContext(AuthContext);

                    if (!user || user.role != UserRole.ADMIN) {
                        return <Navigate to='/' />;
                    }

                    return <AdvertisementList />;
                },
            },
            {
                path: 'advertisement/create',
                Component: () => {
                    const { user } = useContext(AuthContext);

                    if (!user || user.role != UserRole.ADMIN) {
                        return <Navigate to='/' />;
                    }

                    return <AdvertisementItemCreate />;
                },
            },
            {
                path: 'advertisement/read',
                Component: () => {
                    const { user } = useContext(AuthContext);

                    if (!user || user.role != UserRole.ADMIN) {
                        return <Navigate to='/' />;
                    }

                    return <AdvertisementItemRead />;
                },
            },
            {
                path: 'advertisement/update',
                Component: () => {
                    const { user } = useContext(AuthContext);

                    if (!user || user.role != UserRole.ADMIN) {
                        return <Navigate to='/' />;
                    }

                    return <AdvertisementItemUpdate />;
                },
            },
            {
                path: 'mass-mail',
                Component: RootSpam,
            },
            {
                path: 'model',
                Component: RootModel,
            },
            {
                path: 'moder',
                Component: () => {
                    const { user } = useContext(AuthContext);
                    if (user.role != UserRole.ADMIN) {
                        return <Navigate to='/root' />;
                    }

                    return <RootModer />
                },
            },
            {
                path: 'moderation',
                Component: RootModeration,
            },
            {
                path: 'tracking',
                Component: () => {
                    const { user } = useContext(AuthContext);
                    if (user.role != UserRole.ADMIN) {
                        return <Navigate to='/root' />;
                    }

                    return <RootTracking />
                },
            },
            {
                path: 'support',
                Component: RootSupport,
            },
            {
                path: 'user-list',
                Component: RootUserList,
            },
            {
                path: 'user-item',
                Component: RootUserItem,
            },
        ],
    },
    {
        path: '/user',
        Component: () => {
            const { alertMessage } = useContext(AlertContext);

            const { user } = useContext(AuthContext);

            if (!user || user.role != UserRole.CLIENT) {
                return <Navigate to='/' />
            }

            return (
                <div className='wrapper'>
                    <UserNavigation />
                    <main className='page'>
                        <Outlet />
                        {
                            0 < alertMessage.length &&
                            <AlertWindow />
                        }
                    </main>
                </div>
            );
        },
        children: [
            {
                path: 'advertisement',
                Component: AdvertisementList,
            },
            {
                path: 'advertisement/create',
                Component: AdvertisementItemCreate,
            },
            {
                path: 'advertisement/read',
                Component: AdvertisementItemRead,
            },
            {
                path: 'advertisement/update',
                Component: AdvertisementItemUpdate,
            },
            {
                path: 'payment',
                Component: UserPayment,
            },
            {
                path: 'support',
                Component: UserSupport,
            },
        ],
    },
    {
        path: '*',
        Component: NotFound,
    }
]);

export const App = () => {
    return (
        <AlertProvider>
            <AuthProvider>
                <SupportProvider>
                    <RouterProvider router={router} />
                </SupportProvider>
            </AuthProvider>
        </AlertProvider>
    );
}