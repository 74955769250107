import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { SpamTable } from '../../common/spam/SpamTable';
import { SpamCreateWindow } from '../../common/spam/SpamCreateWindow';
import { ISpam, getSpamList, getSpamPossibleRecipientList } from '../../../api/spam';
import { AlertContext } from '../../../stor/AlertProvider';


export const RootSpam = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [spamList, setSpamList] = useState<ISpam[]>([]);

    const [recipientList, setRecipientList] = useState<string[]>([]);

    const [request, setRequest] = useState<boolean>(false);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getSpamList(user.bear);
                setSpamList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getSpamPossibleRecipientList(user.bear);
                setRecipientList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    return (
        <>
            <section className='mass-mailing'>
                <div className='mass-mailing__container'>
                    <div className='mass-mailing__title title'>
                        Mass Mail
                    </div>
                    <SpamTable
                        pageSize={10}
                        spamList={spamList}
                        onCreateRequest={() => setRequest(true)}
                    />
                </div>
            </section>
            {
                !!request &&
                <SpamCreateWindow
                    recipientList={recipientList}
                    onCreate={(spam) => {
                        setSpamList([ spam, ...spamList ]);
                        setRequest(false);
                    }}
                    onExit={() => setRequest(false)}
                />
            }
        </>
    );
}