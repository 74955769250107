import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CloseSvg } from '../../../static/svg/CloseSvg';
import { AlertContext } from '../../../stor/AlertProvider';
import { SupportContext } from '../../../stor/SupportProvider';


interface ISupportChatCreateWindowProperties {
    onExit: () => void;
}

export const SupportChatCreateWindow = ({ onExit }: ISupportChatCreateWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { createChat } = useContext(SupportContext);

    const [header, setHeader] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const onSubmit = useCallback(() => {
        try {
            createChat(header, message);
            onExit();
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [header, message]);

    useEffect(() => {
        console.log(Date.now())
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-chat popup popup-active'>
            <div className='create-chat__body popup__body' ref={ref}>
                <div className='create-chat__title popup__title title__small'>
                    Create Chat
                </div>
                <div
                    className='create-chat__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-chat__wrapper popup__wrapper'>
                    <div className='create-chat__data-body'>
                        <div className='create-chat__input top-animation'>
                            <input
                                type='text'
                                value={header}
                                onChange={(e) => setHeader(e.target.value)}
                            />
                            <label>
                                Header
                            </label>
                        </div>
                        <div className='create-chat__textarea top-animation'>
                            <textarea
                                cols={10}
                                rows={5}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <label>
                                Message
                            </label>
                        </div>
                    </div>
                </div>
                <div className='create-chat__buttons popup__buttons'>
                    <button
                        className='create-chat__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}