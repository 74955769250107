import { CloseSvg } from '../../../static/svg/CloseSvg';
import react, { useContext, useEffect, useRef } from 'react';
import { AdvertisementModelView } from './AdvertisementModelView';
import { IAdvertisement, IAdvertisementModel } from '../../../api/advertisement';
import { AlertContext } from '../../../stor/AlertProvider';


interface IReadAdvertisementModelWindowProperties {
    advertisement: IAdvertisement;
    model: IAdvertisementModel;
    onExit: () => void;
}

export const ReadAdvertisementModelWindow = ({
    advertisement,
    model,
    onExit
}: IReadAdvertisementModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage } = useContext(AlertContext);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);

        return () => {
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage]);

    return (
        <div className='view-card popup popup-active'>
            <div className='view-card__body popup__body' ref={ref}>
                <div
                    className='view-card__close  popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='view-cadr__wrapper popup__wrapper'>
                    <div className='view-card__presentation presentation'>
                        <AdvertisementModelView
                            advertisement={advertisement}
                            model={model}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}