import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';
import { AlertContext } from './AlertProvider';
import { AuthContext } from './AuthProvider';
import { socketServer } from '../api';
import { getSupportChatList, ISupportChat, ISupportChatMessage } from '../api/support';
import { UserRole } from '../api/auth';


interface SupportContextValue {
    supportData: { bear: string, socket: Socket, chatList: ISupportChat[] } | null;
    createChat: (header: string, body: string) => void;
    readChat: (chatId: string) => void;
    deleteChat: (chatId: string) => void;
    createChatMessage: (chatId: string, body: string) => void;
}

export const SupportContext = createContext<SupportContextValue>(null);

export const SupportProvider = ({ children }) => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [data, setData] = useState<{ bear: string, socket: Socket, chatList: ISupportChat[] } | null>(null);

    useEffect(() => {
        if (!user && !!data) {
            try {
                data.socket.close();
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }

            setData(null);
        }

        if (!!user && (!data || user.bear != data.bear)) {
            if (!!data) {
                try {
                    data.socket.close();
                }
                catch (error) {
                    console.error(error);
                    setAlertMessage(String(error));
                }
            }

            const work = async () => {
                try {
                    const socket = io(socketServer, { path: '/support', auth: { bear: user.bear } });

                    const chatList = await getSupportChatList(user.bear);

                    setData({ bear: user.bear, socket: socket, chatList: chatList });
                }
                catch (error) {
                    console.error(error);
                    setAlertMessage(String(error));

                    setData(null);
                }
            }

            work();
        }
    }, [user]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const onCreateChat = (nextChat: ISupportChat) => {
            nextChat.unread_root_message_number = Number(nextChat.unread_root_message_number);
            nextChat.unread_user_message_number = Number(nextChat.unread_user_message_number);
            nextChat.create_moment = new Date(nextChat.create_moment);

            setData({ ...data, chatList: [nextChat, ...data.chatList] });
        }

        const onCreateMessage = (nextChat: ISupportChat, nextMessage: ISupportChatMessage) => {
            nextChat.unread_root_message_number = Number(nextChat.unread_root_message_number);
            nextChat.unread_user_message_number = Number(nextChat.unread_user_message_number);
            nextChat.create_moment = new Date(nextChat.create_moment);

            if (user.role == UserRole.CLIENT) {
                if (nextChat.user_id == nextMessage.user_id) {
                    return;
                }
            }
            else {
                if (nextChat.user_id != nextMessage.user_id) {
                    return;
                }
            }

            setData({ ...data, chatList: [...data.chatList.map((chat) => chat.id == nextChat.id ? nextChat : chat)] });
        }

        const onReadChat = (nextChat: ISupportChat) => {
            nextChat.unread_root_message_number = Number(nextChat.unread_root_message_number);
            nextChat.unread_user_message_number = Number(nextChat.unread_user_message_number);
            nextChat.create_moment = new Date(nextChat.create_moment);

            if (user.role == UserRole.CLIENT) {
                const prevChat = data.chatList.find((chat) => chat.id == nextChat.id);
                if (!prevChat || prevChat.unread_root_message_number == nextChat.unread_root_message_number) {
                    return;
                }
            }
            else {
                const prevChat = data.chatList.find((chat) => chat.id == nextChat.id);
                if (!prevChat || prevChat.unread_user_message_number == nextChat.unread_user_message_number) {
                    return;
                }
            }

            setData({ ...data, chatList: [...data.chatList.map((chat) => chat.id == nextChat.id ? nextChat : chat)] });
        }

        const onArchiveChat = (nextChat: ISupportChat) => {
            nextChat.unread_root_message_number = Number(nextChat.unread_root_message_number);
            nextChat.unread_user_message_number = Number(nextChat.unread_user_message_number);
            nextChat.create_moment = new Date(nextChat.create_moment);

            setData({ ...data, chatList: [...data.chatList.map((chat) => chat.id == nextChat.id ? nextChat : chat)] });
        }

        data.socket.on('create-chat', onCreateChat);
        data.socket.on('create-message', onCreateMessage);
        data.socket.on('read-chat', onReadChat);
        data.socket.on('archive-chat', onArchiveChat);

        return () => {
            data.socket.off('create-chat', onCreateChat);
            data.socket.off('create-message', onCreateMessage);
            data.socket.off('read-chat', onReadChat);
            data.socket.off('archive-chat', onArchiveChat);
        }
    }, [data]);

    const createChat = useCallback((header: string, body: string) => {
        if (!data) {
            throw new Error('Connection problem.');
        }

        if (header.length == 0 || 128 < header.length) {
            throw new Error('Header must be a non-empty string no longer than 128 characters.');
        }

        if (body.length == 0 || 1024 < body.length) {
            throw new Error('Message must be a non-empty string no longer than 1024 characters.');
        }

        data.socket.emit('create-chat-request', { header: header, body: body });
    }, [data]);

    const readChat = useCallback((chatId: string) => {
        if (!data) {
            throw new Error('');
        }

        data.socket.emit('read-chat-request', { chat_id: chatId });
    }, [data]);

    const deleteChat = useCallback((chatId: string) => {
        if (!data) {
            throw new Error('Connection problem.');
        }

        data.socket.emit('archive-chat-request', { chat_id: chatId });
    }, [data]);

    const createChatMessage = useCallback((chatId: string, body: string) => {
        if (!data) {
            throw new Error('Connection problem.');
        }

        if (body.length == 0 || 1024 < body.length) {
            throw new Error('Message must be a non-empty string no longer than 1024 characters.');
        }

        data.socket.emit('create-message-request', { chat_id: chatId, body: body });
    }, [data]);

    return (
        <SupportContext.Provider
            value={{
                supportData: data,
                createChat: createChat,
                readChat: readChat,
                deleteChat: deleteChat,
                createChatMessage: createChatMessage
            }}
        >
            {children}
        </SupportContext.Provider>
    );
}