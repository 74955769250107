import { CreateSvg } from '../../../../static/svg/CreateSvg';
import react, { useState } from 'react';
import { Paging } from '../../../common/Paging';
import { ISpam } from '../../../../api/spam';
import { dateToString } from '../../../../libs/date';


interface IRootUserItemSpamTableItemProperties {
    spam: ISpam;
}

const RootUserItemSpamTableItem = ({ spam }: IRootUserItemSpamTableItemProperties) => {
    return (
        <div className='table__row'>
            <div className='table__item email-subject'>
                {!!spam.recipient ? spam.recipient : ''}
            </div>
            <div className='table__item email-subject'>
                {spam.header}
            </div>
            <div className='table__item date'>
                {dateToString(spam.create_moment)}
            </div>
        </div>
    );
}


interface IRootUserItemSpamTableProperties {
    spamList: ISpam[];
    onCreateRequest: () => void;
}

export const RootUserItemSpamTable = ({ spamList, onCreateRequest }: IRootUserItemSpamTableProperties) => {
    const pageSize = 3;

    const [page, setPage] = useState<number>(1);

    const spamListSlice = spamList
        .slice((page - 1) * pageSize, page * pageSize);

    return (
        <div className='mass-mailing__table table'>
            <div className='table__row row-table-first'>
                <div className='table__item email-subject'>Recipient</div>
                <div className='table__item email-subject'>Header</div>
                <div className='table__item date'>Create</div>
            </div>
            <button
                className='table__create-mass-mail create-btn popup-link'
                onClick={(e) => { e.preventDefault(); onCreateRequest(); }}
            >
                <CreateSvg />
            </button>
            {
                spamListSlice.map((spam, i) => (
                    <RootUserItemSpamTableItem
                        key={i}
                        spam={spam}
                    />
                ))
            }
            <div className='mass-mailing__paging paging'>
                <Paging
                    page={page}
                    size={spamList.length}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </div>
    );
}