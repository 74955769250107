import { InstagramSvg } from '../../../static/svg/InstagramSvg';
import { TikTokSvg } from '../../../static/svg/TikTokSvg';
import { TwitterSvg } from '../../../static/svg/TwitterSvg';
import { TagSvg } from '../../../static/svg/TagSvg';
import { VideoSvg } from '../../../static/svg/VideoSvg';
import { PhotoSvg } from '../../../static/svg/PhotoSvg';
import { LikeSvg } from '../../../static/svg/LikeSvg';
import react from 'react';
import { IAdvertisement } from '../../../api/advertisement';


interface IAdvertisementViewProperties {
    advertisement: IAdvertisement;
}

export const AdvertisementView = ({ advertisement }: IAdvertisementViewProperties) => {
    return (
        <>
            <div className='presentation__image'>
                <img src={advertisement.avatar} />
            </div>
            <div className='presentation__body'>
                <div className='presentation__name name-presentation'>
                    <span className='name-presentation__nickname'>{advertisement.name}</span>
                    <span className='name-presentation__of-path'>{`onlyfans.com > ${advertisement.nickname}`}</span>
                </div>
                <div className='presentation__values values-presentation'>
                    <div className='values-presentation__likes item-values'>
                        <LikeSvg />
                        <span className='item-values__amount'>{advertisement.like_count}</span>
                    </div>
                    <div className='values-presentation__photo item-values'>
                        <PhotoSvg />
                        <span className='item-values__amount'>{advertisement.photo_count}</span>
                    </div>
                    <div className='values-presentation__video item-values'>
                        <VideoSvg />
                        <span className='item-values__amount'>{advertisement.video_count}</span>
                    </div>
                    <div className='values-presentation__price item-values'>
                        <TagSvg />
                        <span className='item-values__amount'>
                            {0 < Number(advertisement.subscribe_cost) ? advertisement.subscribe_cost : 'Free'}
                        </span>
                    </div>
                </div>
                <div className='presentation__description'>
                    <p>{advertisement.about}</p>
                </div>
                <div className='presentation__social social'>
                    {
                        !!advertisement.twitter &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://twitter.com/${advertisement.twitter}`}
                        >
                            <TwitterSvg />
                        </a>
                    }
                    {
                        !!advertisement.tiktok &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://tiktok.com/${advertisement.tiktok}`}
                        >
                            <TikTokSvg />
                        </a>
                    }
                    {
                        !!advertisement.instagram &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://instagram.com/${advertisement.instagram}`}
                        >
                            <InstagramSvg />
                        </a>
                    }
                </div>
            </div>
        </>
    );
}