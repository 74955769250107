import { server } from '.';


export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
    COUPLE = 'couple',
    TRANSGENDER = 'transgender',
}

export enum Subscription {
    PAID = 'paid',
    FREE = 'free',
}

export interface IModel {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    like_count: number;
    photo_count: number;
    video_count: number;
    subscribe_cost: number;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    is_incorrect: boolean;
    create_moment: Date;
    update_moment: Date;
}

export interface ICreateModelDTO {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    like_count: number;
    photo_count: number;
    video_count: number;
    subscribe_cost: number;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
}

export interface IUpdateModelDTO {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
}

export const createModel = async (dto: ICreateModelDTO, bear: string) => {
    const r = await fetch(
        `${server}/model`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
            body: JSON.stringify(dto),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel;
    res.create_moment = new Date(res.create_moment);
    res.update_moment = new Date(res.update_moment);

    return res;
}

export const getModel = async (nickname: string, bear: string) => {
    const r = await fetch(
        `${server}/model/${nickname}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel;
    res.create_moment = new Date(res.create_moment);
    res.update_moment = new Date(res.update_moment);

    return res;
}

export const isModelExist = async (nickname: string, bear: string): Promise<boolean> => {
    const r = await fetch(
        `${server}/model/${nickname}/exist`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const isExist = await r.json() as boolean;

    return isExist;
}

export const getModelList = async (bear: string) => {
    const r = await fetch(
        `${server}/model`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel[];
    res.forEach((model) => {
        model.create_moment = new Date(model.create_moment);
        model.update_moment = new Date(model.update_moment);
    });

    return res;
}

export const updateModel = async (dto: IUpdateModelDTO, bear: string) => {
    const r = await fetch(
        `${server}/model`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
            body: JSON.stringify(dto),
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel;
    res.create_moment = new Date(res.create_moment);
    res.update_moment = new Date(res.update_moment);

    return res;
}

export const refreshModel = async (nickname: string, bear: string) => {
    const r = await fetch(
        `${server}/model/${nickname}/refresh`,
        {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${bear}`,
            }
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel;
    res.create_moment = new Date(res.create_moment);
    res.update_moment = new Date(res.update_moment);

    return res;
}

export const deleteModel = async (nickname: string, bear: string) => {
    const r = await fetch(
        `${server}/model/${nickname}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IModel;
    res.create_moment = new Date(res.create_moment);
    res.update_moment = new Date(res.update_moment);

    return res;
}