import { ReadSvg } from '../../../../static/svg/ReadSvg';
import { StatusSafeSvg } from '../../../../static/svg/StatusSafeSvg';
import { StatusOnArchiveSvg } from '../../../../static/svg/StatusOnArchiveSvg';
import { StatusWaitSvg } from '../../../../static/svg/StatusWaitSvg';
import { StatusUnsafeSvg } from '../../../../static/svg/StatusUnsafeSvg';
import react, { useState } from 'react';
import { Paging } from '../../../common/Paging';
import { IAdvertisementModel, IAdvertisement, AdvertisementModelStatus } from '../../../../api/advertisement';
import { dateToString } from '../../../../libs/date';


interface IAdvertisementItemReadTableItemProperties {
    item: IAdvertisementModel;
    onReadRequest: (m: IAdvertisementModel) => void;
}

const AdvertisementItemReadTableItem = ({
    item,
    onReadRequest
}: IAdvertisementItemReadTableItemProperties) => {
    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button
                    className='actions__view'
                    onClick={(e) => { e.preventDefault(); onReadRequest(item); }}
                >
                    <ReadSvg />
                </button>
            </div>
            <div className='table__item status'>
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE &&
                    <StatusSafeSvg />
                }
                {
                    item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE &&
                    <StatusOnArchiveSvg />
                }
                {
                    item.moderation_status == AdvertisementModelStatus.WAIT &&
                    <StatusWaitSvg />
                }
                {
                    item.moderation_status == AdvertisementModelStatus.UNSAFE &&
                    <StatusUnsafeSvg />
                }
            </div>
            <div className='table__item avatar'>
                <img src={item.avatar} />
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(item.create_moment))}
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(item.update_moment))}
            </div>
            <div className='table__item spent'>
                {item.spent_count.toFixed(2)} $
            </div>
            <div className='table__item clicks'>
                {item.click_count}
            </div>
            <div className='table__item spent'>
                {item.spent_count_today.toFixed(2)} $
            </div>
            <div className='table__item clicks'>
                {item.click_count_today}
            </div>
        </div>
    );
}


interface IAdvertisementItemReadTableProperties {
    item: IAdvertisement;
    onReadRequest: (m: IAdvertisementModel) => void;
}

export const AdvertisementItemReadTable = ({
    item,
    onReadRequest
}: IAdvertisementItemReadTableProperties) => {
    const pageSize = 10;

    const [page, setPage] = useState<number>(1);

    return (
        <>
            <div className='advertisement__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item status'>Status</div>
                    <div className='table__item avatar'>Avatar</div>
                    <div className='table__item create-date'>Create</div>
                    <div className='table__item create-date'>Update</div>
                    <div className='table__item spent'>Spent</div>
                    <div className='table__item clicks'>Click</div>
                    <div className='table__item spent'>SpentToday</div>
                    <div className='table__item clicks'>ClickToday</div>
                </div>
                {
                    item.model_list
                        .sort((a, b) => {
                            if (a.moderation_status != b.moderation_status) {
                                switch (a.moderation_status) {
                                    case AdvertisementModelStatus.WAIT:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return -1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.SAFE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return 1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return 1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.UNSAFE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return 1;
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.NONE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return -1;
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return -1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    default:
                                        throw new Error('Unsupport model status');
                                }
                            }

                            if (a.moderation_status == AdvertisementModelStatus.SAFE && b.moderation_status == AdvertisementModelStatus.SAFE) {
                                if (a.is_delete && !b.is_delete) {
                                    return 1;
                                }
                                if (!a.is_delete && b.is_delete) {
                                    return -1;
                                }
                            }

                            return b.create_moment.getTime() - a.create_moment.getTime();
                        })
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((m) => (
                            <AdvertisementItemReadTableItem
                                key={m.id}
                                item={m}
                                onReadRequest={onReadRequest}
                            />
                        ))
                }
            </div>
            <div className='advertisement__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(item.model_list.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}