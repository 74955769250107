import { ReadSvg } from '../../../../static/svg/ReadSvg';
import { CreateSvg } from '../../../../static/svg/CreateSvg';
import { UpdateSvg } from '../../../../static/svg/UpdateSvg';
import { DeleteSvg } from '../../../../static/svg/DeleteSvg';
import react, { useState } from 'react';
import { Paging } from '../../../common/Paging';
import { IAdvertisementModel, IAdvertisement } from '../../../../api/advertisement';


interface IAdvertisementItemCreateTableItemProperties {
    item: IAdvertisementModel;
    nickname: string;
    onReadRequest: (m: IAdvertisementModel) => void;
    onUpdateRequest: (m: IAdvertisementModel) => void;
    onDeleteRequest: (m: IAdvertisementModel) => void;
}

const AdvertisementItemCreateTableItem = ({
    item,
    nickname,
    onReadRequest,
    onUpdateRequest,
    onDeleteRequest
}: IAdvertisementItemCreateTableItemProperties) => {
    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button
                    className='actions__view'
                    onClick={(e) => { e.preventDefault(); onReadRequest(item); }}
                >
                    <ReadSvg />
                </button>
                <button
                    className='actions__edit'
                    onClick={(e) => { e.preventDefault(); onUpdateRequest(item); }}
                >
                    <UpdateSvg />
                </button>
                <button
                    className='actions__archive popup-link'
                    onClick={(e) => { e.preventDefault(); onDeleteRequest(item); }}
                >
                    <DeleteSvg />
                </button>
            </div>
            <div className='table__item photo'>
                <img src={item.avatar} />
            </div>
            <div className='table__item destination'>
                <a
                    target='_blank'
                    href={
                        !!item.trial
                            ? `https://onlyfans.com/action/trial/${item.trial}`
                            : `https://onlyfans.com/${nickname}${!!item.apath ? `/${item.apath}` : ''}`
                    }
                >
                    Link
                </a>
            </div>
        </div>
    );
}


interface IAdvertisementItemCreateTableProperties {
    item: IAdvertisement;
    onSubmitRequest: () => void;
    onCreateRequest: () => void;
    onReadRequest: (m: IAdvertisementModel) => void;
    onUpdateRequest: (m: IAdvertisementModel) => void;
    onDeleteRequest: (m: IAdvertisementModel) => void;
}

export const AdvertisementItemCreateTable = ({
    item,
    onSubmitRequest,
    onCreateRequest,
    onReadRequest,
    onUpdateRequest,
    onDeleteRequest
}: IAdvertisementItemCreateTableProperties) => {
    const pageSize = 10;

    const [page, setPage] = useState<number>(1);

    return (
        <>
            <div className='advertisement-edit__info info'>
                <span>You can have 10 unarchived advertisement!</span>
            </div>
            <div className='advertisement-edit__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item photo'>Avatar</div>
                    <div className='table__item destination'>Destination URL</div>
                </div>
                <button
                    className='table__create-view create-btn popup-link'
                    onClick={(e) => { e.preventDefault(); onCreateRequest(); }}
                >
                    <CreateSvg />
                </button>
                {
                    item.model_list
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((m) => (
                            <AdvertisementItemCreateTableItem
                                key={m.id}
                                item={m}
                                nickname={item.nickname}
                                onReadRequest={onReadRequest}
                                onUpdateRequest={onUpdateRequest}
                                onDeleteRequest={onDeleteRequest}
                            />
                        ))
                }
            </div>
            <div className='advertisement-edit__control-row control-row'>
                <div className='control-row__body'>
                    <button
                        className='control-row__save-btn save-btn'
                        onClick={(e) => { e.preventDefault(); onSubmitRequest(); }}
                    >
                        Submit
                    </button>
                    <div className='control-row__paging paging'>
                        <Paging
                            page={page}
                            size={Math.ceil(item.model_list.length / pageSize)}
                            setPage={(p) => setPage(p)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}