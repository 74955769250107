import React, { useEffect, useRef, useState } from 'react';
import { OptionPlusSvg } from '../../static/svg/OptionPlusSvg';
import { OptionMinusSvg } from '../../static/svg/OptionMinusSvg';
import { VerticalArrowSvg } from '../../static/svg/VerticalArrowSvg';
import { CloseSvg } from '../../static/svg/CloseSvg';


interface ICategoryProperties {
    categories: [string, string][];
    name: string;
    values: string[];
    setCategory: (name: string, value: string) => void;
}

const Category = ({categories, name, values, setCategory}: ICategoryProperties) => {
    const ref = useRef<HTMLDivElement>();
    
    const [active, setActive] = useState<boolean>(false);
    
    useEffect(() => {
        const work = (e) => {
            if (!ref || ref.current.contains(e['target'])) {
                return;
            }

            setActive(false);
        }

        document.addEventListener('click', work);

        return () => document.removeEventListener('click', work);
    }, []);

    const onChange = (name: string, value: string) => {
        setActive(false);
        setCategory(name, value);
    }

    const isActive = (value: string) => {
        const v = categories.find((category) => category[0] == name);
        if (!v) {
            return false;
        }

        return v[1] == value;
    }

    return (
        <div className={`options__item ${active ? 'active' : ''}`} ref={ref}>
            <div className='options__title' onClick={() => setActive(!active)}>
                <OptionPlusSvg />
                <OptionMinusSvg />
                {name}
            </div>
            <div className='options__item-body'>
                {
                    values.map((value) => {
                        return (
                            <div
                                className={`options__option ${isActive(value) ? 'active' : ''}`}
                                onClick={() => onChange(name, value)}
                                key={value}
                            >
                                {value}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

interface ICategoriesSelectProperties {
    categories: [string, string][];
    setCategories: (categories: [string, string][]) => void;
}

export const CategoriesSelect = ({categories, setCategories}: ICategoriesSelectProperties) => {
    const categoriesConf: [string, string[]][] = [
        ['body', ['athletic', 'chubby', 'curvy', 'fat', 'muscle', 'normal', 'skinny']],
        ['skin', ['black', 'brown', 'caramel', 'tan', 'white']],
        ['hair', ['blonde', 'brown', 'brunette', 'red']],
        ['eye', ['blue', 'brown', 'green']],
        ['ethnic', ['arabic', 'asian', 'ebony', 'european', 'latino']],
    ];

    const [active, setActive] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>();
    useEffect(() => {
        const work = (e) => {
            if (!ref || ref.current.contains(e['target'])) {
                return;
            }

            setActive(false);
        }

        document.addEventListener('click', work);

        return () => {
            document.removeEventListener('click', work);
        }
    }, []);

    return (
        <div className='custom-select top-animation'>
            <div className={`custom-select__body ${active ? 'open' : ''}`}  ref={ref}>
                <div className='custom-select__select-box select-box' onClick={() => setActive(!active)}>
                    <div className='select-box__selected-options'>
                        {
                            categories.slice(0, 1).map((category) => {
                                return (
                                    <span
                                        className='select-box__tag'
                                        key={`${category[0]} - ${category[1]}`}
                                    >
                                        <span>
                                            {`${category[0]} - ${category[1]}`}
                                        </span>
                                        <span
                                            className='select-box__remove-tag'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setCategories(categories.filter((c) => c[0] != category[0]));
                                            }}
                                        >
                                            <CloseSvg />
                                        </span>
                                    </span>
                                );
                            })
                        }
                        {
                            1 < categories.length &&
                            <span className='select-box__tag' key={'count'}>{`+${categories.length - 1}`}</span>
                        }
                    </div>
                    <div className='select-box__arrow'>
                        <VerticalArrowSvg />
                    </div>
                </div>
                <div className='custom-select__options options'>
                    {
                        categoriesConf.map((conf) => (
                            <Category
                                key={`${conf[0]}`} 
                                categories={categories}
                                name={conf[0]}
                                values={conf[1]}
                                setCategory={(name, value) => setCategories([...categories.filter((c) => c[0] != name), [name, value]])}
                            />
                        ))
                    }
                    
                </div>
            </div>
            <label>Categories</label>
        </div>
    );
}