import react, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { ISuccessPayment, IWaitPayment, getPayments } from '../../../api/payment';
import { ReadUserPayment } from './ReadUserPayment';
import { CreateUserPayment } from './CreateUserPayment';
import { ReadUserPaymentList } from './ReadUserPaymentList';
import { AlertContext } from '../../../stor/AlertProvider';


export const UserPayment = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user, updateBalance } = useContext(AuthContext);

    const [data, setData] = useState<{list: ISuccessPayment[], request: IWaitPayment | null}>({
        list: [],
        request: null,
    });

    useEffect(() => {
        const work = async () => {
            try {
                const [list, request] = await getPayments(user.bear);
                setData({list, request});
                updateBalance();
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    const onCreate = (request: IWaitPayment) => {
        setData({list: data.list, request});
    }

    const onChange = () => {
        const work = async () => {
            try {
                const [list, request] = await getPayments(user.bear);
                setData({list, request});
                updateBalance();
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }

    const onCancel = () => {
        setData({list: data.list, request: null});
    }

    return (
        <section className='funds'>
            <div className='funds__container'>
                {
                    data.request === null
                        ? <CreateUserPayment onCreate={onCreate} />
                        : <ReadUserPayment request={data.request} onChange={onChange} onCancel={onCancel} />
                }
                <ReadUserPaymentList list={data.list} />
            </div>
        </section>
    );
}