import React from 'react';

export const CreateSvg = () => {
    return (
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <path d='M4 12H20M12 4V20' stroke='#000000' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </svg>
    );
}