import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IModel, IUpdateModelDTO, updateModel } from '../../../api/model';
import { AuthContext } from '../../../stor/AuthProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';
import { ModelEdit } from '../../common/model/ModelEdit';
import { ModelView } from '../../common/model/ModelView';
import { AlertContext } from '../../../stor/AlertProvider';


interface IUpdateRootModelWindowProperties {
    inp: IModel;
    onUpdate: (model: IModel) => void;
    onExit: () => void;
}

export const UpdateRootModelWindow = ({ inp, onUpdate, onExit }: IUpdateRootModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [model, setModel] = useState<IModel>(inp);

    const onSubmit = useCallback(async () => {
        try {
            const dto: IUpdateModelDTO = {
                nickname: model.nickname,
                name: model.name,
                about: model.about,
                avatar: model.avatar,
                gender: model.gender,
                categories: model.categories,
            };

            const r = await updateModel(dto, user.bear);
            onUpdate(r);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [model, onUpdate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-adv-admin popup popup-active'>
            <div className='create-adv-admin__body popup__body' ref={ref}>
                <h3 className='create-adv-admin__title popup__title title__small'>
                    Update Model
                </h3>
                <div
                    className='create-adv-admin__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-adv-admin__wrapper'>
                    <div className='create-adv-admin__presentation presentation'>
                        <ModelView
                            model={model}
                        />
                    </div>
                    <div className='create-adv-admin__data data-create-view'>
                        <div className='data-create-view__body'>
                            <ModelEdit
                                model={model}
                                setModel={(m) => setModel(m)}
                            />
                            <button
                                className='create-adv-admin__submit'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}