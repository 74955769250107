import react, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { IAdvertisement, IAdvertisementModel } from '../../../api/advertisement';
import { getAdvertisementListForModeration } from '../../../api/moderation';
import { RootModerationAdvertisementModelList } from './RootModerationAdvertisementModelList';
import { UpdateRootModerationAdvertisementModelWindow } from './UpdateRootModerationAdvertisementModelWindow';
import { AlertContext } from '../../../stor/AlertProvider';


interface IRequest {
    advertisement: IAdvertisement;
    model: IAdvertisementModel;
}

export const RootModeration = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [advertisementList, setAdvertisementList] = useState<IAdvertisement[]>([]);

    const [request, setRequest] = useState<IRequest>(null);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getAdvertisementListForModeration(user.bear);
                setAdvertisementList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));    
            }
        }

        work();
    }, []);

    const onUpdate = (advertisement: IAdvertisement, model: IAdvertisementModel) => {
        setRequest(null);

        const prev = { ...advertisementList.find((a) => a.nickname == advertisement.nickname) };
        prev.model_list = prev.model_list.filter((m) => m.id != model.id);

        if (prev.model_list.length == 0) {
            setAdvertisementList(advertisementList.filter((a) => a.nickname != prev.nickname));
        }
        else {
            setAdvertisementList(advertisementList.map((a) => a.nickname != prev.nickname ? a : prev));
        }
    }

    return (
        <>
            <section className='advertisement-view'>
                <div className='advertisement-view__container'>
                    <h2 className='advertisement-view__title title'>Moderation List</h2>
                    <RootModerationAdvertisementModelList
                            advertisementList={advertisementList}
                            onUpdateRequest={(a, m) => setRequest({ advertisement: a, model: m })}
                        />
                </div>
            </section>
            {
                !!request &&
                <UpdateRootModerationAdvertisementModelWindow
                    advertisement={request.advertisement}
                    model={request.model}
                    onUpdate={onUpdate}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}