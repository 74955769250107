import react, { useState } from 'react';
import { Paging } from '../../../common/Paging';
import { ISuccessPayment } from '../../../../api/payment';
import { dateToString } from '../../../../libs/date';


interface IRootUserItemPaymentTableItemProperties {
    payment: ISuccessPayment;
}

const RootUserItemPaymentTableItem = ({ payment }: IRootUserItemPaymentTableItemProperties) => {
    return (
        <div className='table__row' key={payment.transaction_hash}>
            <div className='table__item hesh'>
                <a
                    className='hesh-link'
                    href={`https://tronscan.org/#/transaction/${payment.transaction_hash}`}
                    target='_blank'
                >
                    {payment.transaction_hash}
                </a>
            </div>
            <div className='table__item date'>
                {dateToString(payment.moment)}
            </div>
            <div className='table__item declared'>
                {`${payment.amount} $`}
            </div>
        </div>
    );
}

interface IRootUserItemPaymentTableProperties {
    paymentList: ISuccessPayment[];
}

export const RootUserItemPaymentTable = ({ paymentList }: IRootUserItemPaymentTableProperties) => {
    const pageSize = 3;

    const [page, setPage] = useState<number>(1);

    const paymentListSlice = paymentList
        .slice((page - 1) * pageSize, page * pageSize);

    return (
        <div className='about-user__table table'>
            <div className='table__row row-table-first'>
                <div className='table__item hesh'>
                    Tronscan link
                </div>
                <div className='table__item date'>
                    Date
                </div>
                <div className='table__item declared'>
                    Amount
                </div>
            </div>
            {
                paymentListSlice.map((payment) => (
                    <RootUserItemPaymentTableItem
                        payment={payment}
                    />
                ))
            }
            <div className='about-user__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(paymentList.length / pageSize)}
                    setPage={(x) => setPage(x)}
                />
            </div>
        </div>
    );
}