import { ReadSvg } from '../../../../static/svg/ReadSvg';
import react, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Paging } from '../../../common/Paging';
import { IStatUser } from '../../../../api/stat';
import { dateToString } from '../../../../libs/date';


interface IRootUserTableItemProperties {
    stat: IStatUser;
}

const RootUserTableItem = ({ stat }: IRootUserTableItemProperties) => {
    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <Link className='actions__view' to={`/root/user-item?user_id=${stat.id}`}>
                    <ReadSvg />
                </Link>
            </div>
            <div className='table__item email'>
                { stat.email }
            </div>
            <div className='table__item country'>
                { stat.region }
            </div>
            <div className='table__item total-sum'>
                { `${ stat.balance } $` }
            </div>
            <div className='table__item total-sum'>
                { `${ stat.deposit } $` }
            </div>
            <div className='table__item total-sum'>
                { `${ stat.withdraw } $` }
            </div>
            <div className='table__item transaction-number'>
                { stat.chat_number }
            </div>
            <div className='table__item transaction-number'>
                { stat.mail_number }
            </div>
            <div className='table__item transaction-number'>
                { stat.transaction_number }
            </div>
            <div className='table__item date'>
                { dateToString(stat.create_moment) }
            </div>
        </div>
    );
}

interface IRootUserTableProperties {
    statList: IStatUser[];
}

export const RootUserTable = ({ statList }: IRootUserTableProperties) => {
    const pageSize = 10;

    const [page, setPage] = useState<number>(1);

    console.log(statList.length)

    return (
        <>
            <div className='users-admin__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>
                        Actions
                    </div>
                    <div className='table__item email'>
                        Email
                    </div>
                    <div className='table__item country'>
                        Region
                    </div>
                    <div className='table__item total-sum'>
                        Balance
                    </div>
                    <div className='table__item total-sum'>
                        Deposit
                    </div>
                    <div className='table__item total-sum'>
                        Withdraw
                    </div>
                    <div className='table__item transaction-number'>
                        Chats
                    </div>
                    <div className='table__item transaction-number'>
                        Mails
                    </div>
                    <div className='table__item transaction-number'>
                        Payments
                    </div>
                    <div className='table__item date'>
                        Create
                    </div>
                </div>
                {
                    statList
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((stat) => {
                            return (
                                <RootUserTableItem
                                    key={stat.id}
                                    stat={stat}
                                />
                            );
                        })
                }
            </div>
            <div className='users-admin__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(statList.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}