import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../stor/AuthProvider';
import { signIn } from '../../api/auth';
import { AlertContext } from '../../stor/AlertProvider';


export const SignIn = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { auth } = useContext(AuthContext);

    const [email, setEmail] = useState<string>('');

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');

    const onSubmit = useCallback(async () => {
        try {
            const user = await signIn(email, password);
            auth(user);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [email, password]);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [onSubmit]);

    return (
        <section className='login'>
            <div className='login__container'>
                <div className='login__item item'>
                    <h3 className='item__welcome'>
                        Sign In
                    </h3>
                    <div className='item__wrapper'>
                        <div className='item__input email-item top-animation'>
                            <input
                                className='email-item__input'
                                type='text'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label>
                                Email
                            </label>
                        </div>
                        <div className='item__input password-item top-animation'>
                            <input
                                className='password-item__input'
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <label>
                                Password
                            </label>
                            <svg
                                className='password-item__image'
                                viewBox='0 0 1024 1024'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='#000000'
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                                <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                                <g id='SVGRepo_iconCarrier'>
                                    <path fill='#000000' d='M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z' />
                                </g>
                            </svg>
                        </div>
                        <button
                            className='item__btn'
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            Submit
                        </button>
                        <div className='item__links links'>
                            <Link className='links__item' to='/auth/sign-up/start'>
                                Sign Up
                            </Link>
                            <Link className='links__item' to='/auth/sign-re/start'>
                                Change Password
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}