import react from 'react';
import { IAdvertisement } from '../../../api/advertisement';


interface IAdvertisementStatProperties {
    advertisementList: IAdvertisement[];
}

export const AdvertisementStat = ({ advertisementList }: IAdvertisementStatProperties) => {
    const active = advertisementList.reduce((x, a) => x + ((!a.is_delete && a.is_active) ? 1 : 0), 0);
    const onPause = advertisementList.reduce((x, a) => x + ((!a.is_delete && !a.is_active) ? 1 : 0), 0);
    const onArchive = advertisementList.reduce((x, a) => x + (a.is_delete ? 1 : 0), 0);

    const clickCount = advertisementList.reduce((x, a) => x + a.model_list.reduce((y, m) => y + m.click_count, 0), 0);

    const cpc = 0 < advertisementList.length
        ? advertisementList.reduce((x, a) => x + Number(a.click_cost), 0) / advertisementList.length
        : 0;

    const spent = advertisementList.reduce((x, a) => x + a.model_list.reduce((y, m) => y + m.spent_count, 0), 0);

    return (
        <div className='advertisement__stat stat'>
            <div className='stat__item'>
                <span className='stat__title'>Active/OnPause/OnArchive</span>
                <span className='stat__amount'>{active}/{onPause}/{onArchive}</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>ClickCount</span>
                <span className='stat__amount'>{clickCount}</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>CPC</span>
                <span className='stat__amount'>{cpc.toFixed(2)} $</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>Spent</span>
                <span className='stat__amount'>{spent.toFixed(2)} $</span>
            </div>
        </div>
    );
}