import react, { useState } from 'react';
import { ISuccessPayment } from '../../../api/payment';
import { Paging } from '../../common/Paging';
import { dateToString } from '../../../libs/date';


interface IReadUserPaymentListProperties {
    list: ISuccessPayment[];
}

export const ReadUserPaymentList = ({ list }: IReadUserPaymentListProperties) => {
    const pageSize = 5;

    const [page, setPage] = useState<number>(1);

    return (
        <>
            <div className='funds__history history'>
                <div className='history__table table'>
                    <div className='table__row row-table-first'>
                        <div className='table__item hesh'>
                            Tronscan link
                        </div>
                        <div className='table__item date'>
                            Date
                        </div>
                        <div className='table__item declared'>
                            Amount
                        </div>
                    </div>
                    {
                        list.slice(pageSize * (page - 1), pageSize * page).map((payment) => (
                            <div className='table__row' key={payment.transaction_hash}>
                                <div className='table__item hesh'>
                                    <a
                                        className='hesh-link'
                                        href={`https://tronscan.org/#/transaction/${payment.transaction_hash}`}
                                    >
                                        {payment.transaction_hash}
                                    </a>
                                </div>
                                <div className='table__item date'>
                                    {dateToString(payment.moment)}
                                </div>
                                <div className='table__item declared'>
                                    {payment.amount} $
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='funds__paging paging'>
                <Paging
                    size={Math.ceil(list.length / pageSize)}
                    page={page}
                    setPage={(x) => setPage(x)}
                />
            </div>
        </>
    );
}