import React from 'react';
import { IModel } from '../../../api/model';
import { LikeSvg } from '../../../static/svg/LikeSvg';
import { PhotoSvg } from '../../../static/svg/PhotoSvg';
import { VideoSvg } from '../../../static/svg/VideoSvg';
import { TagSvg } from '../../../static/svg/TagSvg';
import { TwitterSvg } from '../../../static/svg/TwitterSvg';
import { TikTokSvg } from '../../../static/svg/TikTokSvg';
import { InstagramSvg } from '../../../static/svg/InstagramSvg';


interface IModelViewProperties {
    model: IModel;
}

export const ModelView = ({ model }: IModelViewProperties) => {
    return (
        <>
            <div className='presentation__image'>
                <img src={model.avatar} />
            </div>
            <div className='presentation__body'>
                <div className='presentation__name name-presentation'>
                    <span className='name-presentation__nickname'>{model.name}</span>
                    <span className='name-presentation__of-path'>{`onlyfans.com > ${model.nickname}`}</span>
                </div>
                <div className='presentation__values values-presentation'>
                    <div className='values-presentation__likes item-values'>
                        <LikeSvg />
                        <span className='item-values__amount'>{model.like_count}</span>
                    </div>
                    <div className='values-presentation__photo item-values'>
                        <PhotoSvg />
                        <span className='item-values__amount'>{model.photo_count}</span>
                    </div>
                    <div className='values-presentation__video item-values'>
                        <VideoSvg />
                        <span className='item-values__amount'>{model.video_count}</span>
                    </div>
                    <div className='values-presentation__price item-values'>
                        <TagSvg />
                        <span className='item-values__amount'>
                            {0 < Number(model.subscribe_cost) ? model.subscribe_cost : 'Free'}
                        </span>
                    </div>
                </div>
                <div className='presentation__description'>
                    <p>{model.about}</p>
                </div>
                <div className='presentation__social social'>
                    {
                        !!model.twitter &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://twitter.com/${model.twitter}`}
                        >
                            <TwitterSvg />
                        </a>
                    }
                    {
                        !!model.tiktok &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://tiktok.com/${model.tiktok}`}
                        >
                            <TikTokSvg />
                        </a>
                    }
                    {
                        !!model.instagram &&
                        <a
                            className='social__link'
                            target='_blank'
                            href={`https://www.instagram.com/${model.instagram}`}
                        >
                            <InstagramSvg />
                        </a>
                    }
                </div>
            </div>
        </>
    );
}