import react, { useCallback, useContext, useEffect, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { AuthContext } from '../../../stor/AuthProvider';
import { PlotIntervalForm } from './PlotIntervalForm';
import { getStatPlot, StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../api/stat';
import { AlertContext } from '../../../stor/AlertProvider';


const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

    const RADIAN = Math.PI / 180;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={300} dy={8} textAnchor={'middle'} fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill={'none'} />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke={'none'} />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={'#333'}>
                {`Count ${value}`}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={'#999'}>
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

interface IPiePlotProperties {
    header: string;
    wait: boolean;
    obj: StatPlotObject;
    sub: StatPlotSubject;
    pre: StatPlotPredicat;
    objId?: string;
}

export const PiePlot = ({ header, wait, obj, sub, pre, objId }: IPiePlotProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [activeIndex, setActiveIndex] = useState(0);

    const [from, setFrom] = useState<Date>(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    const [to, setTo] = useState<Date>(new Date());

    const [data, setData] = useState<{ name: string, value: number }[]>([]);

    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        if (wait) {
            return;
        }

        const work = async () => {
            try {
                const r = await getStatPlot(obj, sub, pre, objId, from, to, user.bear);

                setData(r.data);

                setInit(true);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, [wait, objId])

    useEffect(() => {
        if (!init) {
            return;
        }

        const work = async () => {
            try {
                const r = await getStatPlot(obj, sub, pre, objId, from, to, user.bear);

                setData(r.data);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, [from, to, objId]);

    const handleMouseEnter = useCallback((_, index) => {
        setActiveIndex(index);
    }, []);

    return (
        <div className='charts__pie-chart'>
            <h3>
                {header}
            </h3>
            <PlotIntervalForm
                from={from}
                to={to}
                setFrom={(f) => setFrom(f)}
                setTo={(t) => setTo(t)}
            />
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx={'50%'}
                        cy={'50%'}
                        innerRadius={60}
                        outerRadius={70}
                        fill={'#3d3b3b'}
                        dataKey={'value'}
                        onMouseEnter={handleMouseEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}