import { server } from '.';
import { AdvertisementModelStatus, IAdvertisement, IAdvertisementModel } from './advertisement';


const upAdvertisementModel = (nickname: string, model: IAdvertisementModel) => {
    if (!(model.create_moment instanceof Date)) {
        model.create_moment = new Date(model.create_moment);
    }
    if (!(model.update_moment instanceof Date)) {
        model.update_moment = new Date(model.update_moment);
    }

    if (!model.avatar) {
        model.avatar = `${server}/advertisement/model/avatar/${model.id}?data=${Date.now()}`;
    }

    if (!!model.apath) {
        model.destination_url = `https://onlyfans.com/${nickname}/${model.apath}`;
    }
    else if (!!model.trial) {
        model.destination_url = `https://onlyfans.com/action/trial/${model.trial}`;
    }
    else {
        model.destination_url = `https://onlyfans.com/${nickname}`;
    }
}

const upAdvertisement = (advertisement: IAdvertisement) => {
    if (!(advertisement.create_moment instanceof Date)) {
        advertisement.create_moment = new Date(advertisement.create_moment);
    }
    if (!(advertisement.update_moment instanceof Date)) {
        advertisement.update_moment = new Date(advertisement.update_moment);
    }
    advertisement.model_list.forEach((model) => upAdvertisementModel(advertisement.nickname ,model));
}

export const getAdvertisementListForModeration = async (bear: string): Promise<IAdvertisement[]> => {
    const r = await fetch(
        `${server}/moderation/advertisement`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            }
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
    
    const data: IAdvertisement[] = await r.json();
    data.forEach((a) => upAdvertisement(a));
    return data;
}

export const updateAdvertisementModelForModeration = async (
    advertisement: IAdvertisement,
    id: string,
    status: AdvertisementModelStatus.SAFE | AdvertisementModelStatus.UNSAFE,
    bear: string
): Promise<IAdvertisementModel> => {
    const r = await fetch(
        `${server}/moderation/advertisement/model?id=${id}&status=${status}`,
        {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${bear}`,
            }
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
    
    const data: IAdvertisementModel = await r.json();
    upAdvertisementModel(advertisement.nickname, data);
    return data;
}