import { ReadSvg } from '../../../../static/svg/ReadSvg';
import { CreateSvg } from '../../../../static/svg/CreateSvg';
import { PauseSvg } from '../../../../static/svg/PauseSvg';
import { UpdateSvg } from '../../../../static/svg/UpdateSvg';
import { PlaySvg } from '../../../../static/svg/PlaySvg';
import { ArchiveSvg } from '../../../../static/svg/ArchiveSvg';
import { DeleteSvg } from '../../../../static/svg/DeleteSvg';
import { StatusSafeSvg } from '../../../../static/svg/StatusSafeSvg';
import { StatusWaitSvg } from '../../../../static/svg/StatusWaitSvg';
import { StatusUnsafeSvg } from '../../../../static/svg/StatusUnsafeSvg';
import { StatusOnArchiveSvg } from '../../../../static/svg/StatusOnArchiveSvg';
import react, { useContext, useState } from 'react';
import { Paging } from '../../../common/Paging';
import { IAdvertisementModel, IAdvertisement, AdvertisementModelStatus } from '../../../../api/advertisement';
import { dateToString } from '../../../../libs/date';
import { AuthContext } from '../../../../stor/AuthProvider';
import { UserRole } from '../../../../api/auth';


interface IAdvertisementItemUpdateTableItemProperties {
    item: IAdvertisementModel;
    nickname: string;
    onReadRequest: (m: IAdvertisementModel) => void;
    onUpdateRequest: (m: IAdvertisementModel) => void;
    onSwitchRequest: (m: IAdvertisementModel) => void;
    onArchiveRequest: (m: IAdvertisementModel) => void;
    onDeleteRequest: (m: IAdvertisementModel) => void;
}

const AdvertisementItemUpdateTableItem = ({
    item,
    nickname,
    onReadRequest,
    onUpdateRequest,
    onSwitchRequest,
    onArchiveRequest,
    onDeleteRequest
}: IAdvertisementItemUpdateTableItemProperties) => {
    const { user } = useContext(AuthContext);

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                {
                    item.is_delete || item.moderation_status == AdvertisementModelStatus.WAIT &&
                    <button
                        className='actions__view'
                        onClick={(e) => { e.preventDefault(); onReadRequest(item); }}
                    >
                        <ReadSvg />
                    </button>
                }
                {
                    !item.is_delete && item.moderation_status != AdvertisementModelStatus.WAIT &&
                    <button
                        className='actions__edit'
                        onClick={(e) => { e.preventDefault(); onUpdateRequest(item); }}
                    >
                        <UpdateSvg />
                    </button>
                }
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE && !item.is_active &&
                    <button
                        className='actions__pause-play pause-play'
                        onClick={(e) => { e.preventDefault(); onSwitchRequest(item); }}
                    >
                        <PlaySvg />

                    </button>
                }
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE && item.is_active &&
                    <button
                        className='actions__pause-play pause-play active'
                        onClick={(e) => { e.preventDefault(); onSwitchRequest(item); }}
                    >
                        <PauseSvg />
                    </button>
                }
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE &&
                    <button
                        className='actions__archive popup-link'
                        onClick={(e) => { e.preventDefault(); onArchiveRequest(item); }}
                    >
                        <ArchiveSvg />
                    </button>
                }
                {
                    (item.moderation_status == AdvertisementModelStatus.NONE || item.moderation_status == AdvertisementModelStatus.UNSAFE || user.role != UserRole.CLIENT) &&
                    <button
                        className='actions__archive popup-link'
                        onClick={(e) => {
                            e.preventDefault();



                            onDeleteRequest(item);
                        }}
                    >
                        <DeleteSvg />
                    </button>
                }
            </div>
            <div className='table__item status'>
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE &&
                    <StatusSafeSvg />
                }
                {
                    item.is_delete && item.moderation_status == AdvertisementModelStatus.SAFE &&
                    <StatusOnArchiveSvg />
                }
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.WAIT &&
                    <StatusWaitSvg />
                }
                {
                    !item.is_delete && item.moderation_status == AdvertisementModelStatus.UNSAFE &&
                    <StatusUnsafeSvg />
                }
            </div>
            <div className='table__item photo'>
                <img src={item.avatar} />
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(item.create_moment))}
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(item.update_moment))}
            </div>
            <div className='table__item destination'>
                <a
                    target='_blank'
                    href={
                        !!item.trial
                            ? `https://onlyfans.com/action/trial/${item.trial}`
                            : `https://onlyfans.com/${nickname}${!!item.apath ? `/${item.apath}` : ''}`
                    }
                >
                    Link
                </a>
            </div>
        </div>
    );
}


interface IAdvertisementItemUpdateTableProperties {
    item: IAdvertisement;
    onSubmitRequest: () => void;
    onCreateRequest: () => void;
    onReadRequest: (m: IAdvertisementModel) => void;
    onUpdateRequest: (m: IAdvertisementModel) => void;
    onSwitchRequest: (m: IAdvertisementModel) => void;
    onArchiveRequest: (m: IAdvertisementModel) => void;
    onDeleteRequest: (m: IAdvertisementModel) => void;
}

export const AdvertisementItemUpdateTable = ({
    item,
    onSubmitRequest,
    onCreateRequest,
    onReadRequest,
    onUpdateRequest,
    onSwitchRequest,
    onArchiveRequest,
    onDeleteRequest
}: IAdvertisementItemUpdateTableProperties) => {
    const pageSize = 10;
    const size = item.model_list.filter((m) => !(m.is_delete && (m.moderation_status == AdvertisementModelStatus.SAFE || m.moderation_status == AdvertisementModelStatus.UNSAFE))).length;

    const [page, setPage] = useState<number>(1);

    return (
        <>
            <div className='advertisement-edit__info info'>
                <span>You can have 10 unarchived advertisement!</span>
            </div>
            <div className='advertisement-edit__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item status'>Status</div>
                    <div className='table__item photo'>Avatar</div>
                    <div className='table__item create-date'>Create</div>
                    <div className='table__item create-date'>Update</div>
                    <div className='table__item destination'>Destination URL</div>
                </div>
                <button
                    className='table__create-view create-btn popup-link'
                    onClick={(e) => { e.preventDefault(); onCreateRequest(); }}
                >
                    <CreateSvg />
                </button>
                {
                    item.model_list
                        .filter((m) => !(m.is_delete && (m.moderation_status == AdvertisementModelStatus.SAFE || m.moderation_status == AdvertisementModelStatus.UNSAFE)))
                        .sort((a, b) => {
                            if (a.moderation_status != b.moderation_status) {
                                switch (a.moderation_status) {
                                    case AdvertisementModelStatus.WAIT:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return -1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.SAFE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return 1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return 1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.UNSAFE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return 1;
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.NONE:
                                                return 1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    case AdvertisementModelStatus.NONE:
                                        switch (b.moderation_status) {
                                            case AdvertisementModelStatus.WAIT:
                                                return -1;
                                            case AdvertisementModelStatus.SAFE:
                                                return -1;
                                            case AdvertisementModelStatus.UNSAFE:
                                                return -1;
                                            default:
                                                throw new Error('Unsupport model status');
                                        }
                                    default:
                                        throw new Error('Unsupport model status');
                                }
                            }

                            if (a.moderation_status == AdvertisementModelStatus.SAFE && b.moderation_status == AdvertisementModelStatus.SAFE) {
                                if (a.is_delete && !b.is_delete) {
                                    return 1;
                                }
                                if (!a.is_delete && b.is_delete) {
                                    return -1;
                                }
                            }

                            return b.create_moment.getTime() - a.create_moment.getTime();
                        })
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((m) => (
                            <AdvertisementItemUpdateTableItem
                                key={m.id}
                                item={m}
                                nickname={item.nickname}
                                onReadRequest={onReadRequest}
                                onUpdateRequest={onUpdateRequest}
                                onSwitchRequest={onSwitchRequest}
                                onArchiveRequest={onArchiveRequest}
                                onDeleteRequest={onDeleteRequest}
                            />
                        ))
                }
            </div>
            <div className='advertisement-edit__control-row control-row'>
                <div className='control-row__body'>
                    <button
                        className='control-row__save-btn save-btn'
                        onClick={(e) => { e.preventDefault(); onSubmitRequest(); }}
                    >
                        Submit
                    </button>
                    <div className='control-row__paging paging'>
                        <Paging
                            page={page}
                            size={Math.ceil(size / pageSize)}
                            setPage={(p) => setPage(p)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}