import react from 'react';
import { AdvertisementView } from '../../common/AdvertisementView';
import { AdvertisementEdit } from '../../common/AdvertisementEdit';
import { IAdvertisement } from '../../../../api/advertisement';


interface IAdvertisementItemCreateBodyProperties {
    advertisement: IAdvertisement;
    setAdvertisement: (a: IAdvertisement) => void;
}

export const AdvertisementItemCreateBody = ({
    advertisement,
    setAdvertisement
}: IAdvertisementItemCreateBodyProperties) => {
    return (
        <div className='advertisement-edit__create-adv create-adv'>
            <div className='create-adv__presentation presentation'>
                <AdvertisementView
                    advertisement={advertisement}
                />
            </div>
            <div className='create-adv__data data-general'>
                <div className='data-general__body'>
                    <AdvertisementEdit
                        advertisement={advertisement}
                        setAdvertisement={setAdvertisement}
                    />
                </div>
            </div>
        </div>
    );
}