import React, { useEffect, useRef, useState } from 'react';
import { VerticalArrowSvg } from '../../static/svg/VerticalArrowSvg';
import { Gender } from '../../api/model';

interface IGenderSelectProperties {
    value: Gender;
    setGender: (gender: Gender) => void;
}

export const GenderSelect = ({value, setGender}: IGenderSelectProperties) => {
    const ref = useRef<HTMLDivElement>();
    
    const [active, setActive] = useState<boolean>(false);
    
    useEffect(() => {
        const work = (e) => {
            if (!ref || ref.current.contains(e['target'])) {
                return;
            }

            setActive(false);
        }

        document.addEventListener('click', work);

        return () => document.removeEventListener('click', work);
    }, []);

    const onChange = (gender: Gender) => {
        setActive(false);
        setGender(gender);
    }

    return (
        <div className={`data-create-view__select top-animation gender-select select ${active ? 'is-active' : ''}`} ref={ref}>
            <div
                className='gender-select__select-header select__header'
                onClick={() => setActive(!active)}
            >
                <span className='gender-select__select-current select__current'>
                    {!!value ? value : ''}
                </span>
                <div className='gender-select__select-icon select__icon'>
                    <VerticalArrowSvg />
                </div>
            </div>
            <div className='gender-select__select-body select__body'>
                {
                    Object.values(Gender).map((gender) => {
                        return (
                            <div
                                className='gender-select__select-item select__item'
                                onClick={() => onChange(gender)}
                                key={gender}
                            >
                                {gender}
                            </div>
                        );
                    })
                }
            </div>
            <label>
                Gender
            </label>
        </div>
    );
}