import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { ISpam, createSpam } from '../../../api/spam';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ISpamCreateWindowProperties {
    recipientList: string[];
    onCreate: (spam: ISpam) => void;
    onExit: () => void;
}

export const SpamCreateWindow = ({ recipientList, onCreate, onExit }: ISpamCreateWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [header, setHeader] = useState<string>('');
    const [body, setBody] = useState<string>('');

    const onSubmit = useCallback(async () => {
        try {
            const r = await createSpam(recipientList, header, body, user.bear);
            onCreate(r);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [recipientList, header, body, onCreate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='mass-mail-popup popup popup-active'>
            <div className='mass-mail-popup__body popup__body' ref={ref}>
                <h3 className='mass-mail-popup__title popup__title title__small'>
                    Create Mail
                </h3>
                <div
                    className='mass-mail-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='mass-mail-popup__wrapper popup__wrapper'>
                    <div className='mass-mail-popup__subject top-animation'>
                        <input
                            type='text'
                            value={header}
                            onChange={(e) => setHeader(e.target.value)}
                        />
                        <label>
                            Header
                        </label>
                    </div>
                    <div className='mass-mail-popup__text top-animation'>
                        <textarea
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                        <label>
                            Body
                        </label>
                    </div>
                </div>
                <div className='mass-mail-popup__buttons popup__buttons'>
                    <button
                        className='mass-mail-popup__btn-send popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}