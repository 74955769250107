import React, { useContext, useEffect, useRef } from 'react';
import { IModel } from '../../../api/model';
import { CloseSvg } from '../../../static/svg/CloseSvg';
import { ModelView } from '../../common/model/ModelView';
import { AlertContext } from '../../../stor/AlertProvider';


interface IReadRootModelWindowProperties {
    model: IModel;
    onExit: () => void;
}

export const ReadRootModelWindow = ({ model, onExit }: IReadRootModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage } = useContext(AlertContext);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);

        return () => {
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage]);

    return (
        <div className='view-card popup popup-active'>
            <div className='view-card__body popup__body' ref={ref}>
                <div
                    className='view-card__close  popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='view-cadr__wrapper popup__wrapper'>
                    <div className='view-card__presentation presentation'>
                        <ModelView model={model} />
                    </div>
                </div>
            </div>
        </div>
    );
}