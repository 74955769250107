import react, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../../stor/AuthProvider';
import { AdvertisementItemCreateBody } from './AdvertisementItemCreateBody';
import { AdvertisementItemCreateTable } from './AdvertisementItemCreateTable';
import { CreateAdvertisementItemCreateWindow } from './CreateAdvertisementItemCreateWindow';
import { CreateAdvertisementModelWindow } from '../../common/CreateAdvertisementModelWindow';
import { ReadAdvertisementModelWindow } from '../../common/ReadAdvertisementModelWindow';
import { UpdateAdvertisementModelWindow } from '../../common/UpdateAdvertisementModelWindow';
import { IAdvertisement, IAdvertisementModel } from '../../../../api/advertisement';
import { Gender, getModel } from '../../../../api/model';
import { AlertContext } from '../../../../stor/AlertProvider';


interface IRequest {
    method: 'SUBMIT' | 'CREATE' | 'READ' | 'UPDATE';
    model?: IAdvertisementModel;
}

export const AdvertisementItemCreate = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const [searchParams, _] = useSearchParams();

    const { user } = useContext(AuthContext);

    const counter = useRef<number>(0);

    const [advertisement, setAdvertisement] = useState<IAdvertisement>(null);

    const [request, setRequest] = useState<IRequest>(null);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (!advertisement || !!request || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();

            setRequest({ method: 'SUBMIT' });
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [advertisement, request]);

    useEffect(() => {
        const work = async () => {
            try {
                const nickname = searchParams.get('nickname');
                if (!nickname) {
                    console.error('!nickname');
                    setAlertMessage('Internal exception');
                    return;
                }

                const m = await getModel(nickname, user.bear);

                setAdvertisement({
                    nickname: m.nickname,
                    name: m.name,
                    about: m.about,
                    avatar: m.avatar,
                    like_count: m.like_count,
                    photo_count: m.photo_count,
                    video_count: m.video_count,
                    subscribe_cost: String(m.subscribe_cost),
                    gender: Gender.FEMALE,
                    categories: [],
                    user_id: '',
                    daily_budget: '100',
                    click_cost: '0.10',
                    is_active: true,
                    is_delete: false,
                    is_incorrect: false,
                    create_moment: new Date(),
                    update_moment: new Date(),
                    model_list: [],
                });
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    const onCreate = (model: IAdvertisementModel) => {
        counter.current += 1;

        setAdvertisement({
            ...advertisement,
            model_list: [
                { ...model, id: String(counter.current) },
                ...advertisement.model_list,
            ],
        });

        setRequest(null);
    }

    const onUpdate = (model: IAdvertisementModel) => {
        setAdvertisement({
            ...advertisement,
            model_list: advertisement.model_list.map((m) => m.id != model.id ? m : model),
        });

        setRequest(null);
    }

    const onDelete = (model: IAdvertisementModel) => {
        setAdvertisement({
            ...advertisement,
            model_list: advertisement.model_list.filter((m) => m.id != model.id),
        });
    }

    return (
        <>
            <section className='advertisement-edit'>
                <div className='advertisement-edit__container'>
                    <h2 className='advertisement-edit__title title'>Create Advertisement</h2>
                    {
                        !!advertisement &&
                        <AdvertisementItemCreateBody
                            advertisement={advertisement}
                            setAdvertisement={(a) => setAdvertisement(a)}
                        />
                    }
                    {
                        !!advertisement &&
                        <AdvertisementItemCreateTable
                            item={advertisement}
                            onSubmitRequest={() => setRequest({ method: 'SUBMIT' })}
                            onCreateRequest={() => setRequest({ method: 'CREATE' })}
                            onReadRequest={(m) => setRequest({ method: 'READ', model: m })}
                            onUpdateRequest={(m) => setRequest({ method: 'UPDATE', model: m })}
                            onDeleteRequest={onDelete}
                        />
                    }
                </div>
            </section>
            {
                !!request && request.method == 'SUBMIT' &&
                <CreateAdvertisementItemCreateWindow
                    advertisement={advertisement}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'CREATE' &&
                <CreateAdvertisementModelWindow
                    advertisement={advertisement}
                    onCreate={onCreate}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'READ' &&
                <ReadAdvertisementModelWindow
                    advertisement={advertisement}
                    model={request.model}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'UPDATE' &&
                <UpdateAdvertisementModelWindow
                    advertisement={advertisement}
                    model={request.model}
                    onUpdate={onUpdate}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}