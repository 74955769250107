import React from 'react';
import { Link } from 'react-router-dom';


export const NotFound = () => {
    return (
        <div className='wrapper'>
            <main className='page'>
                <section className='page-404'>
                    <div className='page-404__container'>
                        <div className='page-404__image'>
                            <svg
                                fill='#000000'
                                viewBox='0 0 36 36'
                                version='1.1'
                                preserveAspectRatio='xMidYMid meet'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                            >
                                <g id='SVGRepo_bgCarrier' stroke-width='0' />
                                <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round' />
                                <g id='SVGRepo_iconCarrier'>
                                    <path
                                        className='clr-i-outline clr-i-outline-path-1'
                                        d='M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z'
                                    />
                                    <circle className='clr-i-outline clr-i-outline-path-2' cx='25.16' cy='14.28' r='1.8' />
                                    <circle className='clr-i-outline clr-i-outline-path-3' cx='11.41' cy='14.28' r='1.8' />
                                    <path
                                        className='clr-i-outline clr-i-outline-path-4'
                                        d='M18.16,20a9,9,0,0,0-7.33,3.78,1,1,0,1,0,1.63,1.16,7,7,0,0,1,11.31-.13,1,1,0,0,0,1.6-1.2A9,9,0,0,0,18.16,20Z'
                                    />
                                    <rect x='0' y='0' width='36' height='36' fill-opacity='0' />
                                </g>
                            </svg>
                        </div>
                        <h3 className='page-404__text'>Not Found Error</h3>
                        <Link className='page-404__link' to='/'>Home Page</Link>
                    </div>
                </section>
            </main>
        </div>
    );
}