import react, { useContext, useEffect, useState } from 'react';
import { SupportChatTable } from '../../common/support/SupportChatTable';
import { SupportChatCreateWindow } from '../../common/support/SupportChatCreateWindow';
import { SupportChatWindow } from '../../common/support/SupportChatWindow';
import { SupportChatDeleteWindow } from '../../common/support/SupportChatDeleteWindow';
import { ISupportChat } from '../../../api/support';
import { SupportContext } from '../../../stor/SupportProvider';


interface IRequest {
    kind: 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';
    chat?: ISupportChat;
}

export const UserSupport = () => {
    const { supportData } = useContext(SupportContext);

    const [request, setRequest] = useState<IRequest | null>(null);

    const [chatList, setChatList] = useState<ISupportChat[]>([]);

    useEffect(() => {
        if (!supportData) {
            setRequest(null);
            setChatList([]);

            return;
        }

        setChatList(supportData.chatList);
    }, [supportData]);

    return (
        <>
            <section className='support'>
                <div className='support__container'>
                    <h2 className='support__title title'>
                        Support
                    </h2>
                    <SupportChatTable
                        pageSize={10}
                        createFlag={true}
                        chatList={chatList}
                        onCreateRequest={() => setRequest({ kind: 'CREATE' })}
                        onReadRequest={(chatId: string) => setRequest({
                            kind: 'READ',
                            chat: chatList.find((chat) => chat.id == chatId),
                        })}
                        onUpdateRequest={(chatId: string) => setRequest({
                            kind: 'UPDATE',
                            chat: chatList.find((chat) => chat.id == chatId),
                        })}
                        onDeleteRequest={(chatId: string) => setRequest({
                            kind: 'DELETE',
                            chat: chatList.find((chat) => chat.id == chatId),
                        })}
                    />
                </div>
            </section>
            {
                !!request && request.kind == 'CREATE' &&
                <SupportChatCreateWindow
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'READ' &&
                <SupportChatWindow
                    createFlag={false}
                    chat={request.chat}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'UPDATE' &&
                <SupportChatWindow
                    createFlag={true}
                    chat={request.chat}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.kind == 'DELETE' &&
                <SupportChatDeleteWindow
                    chat={request.chat}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    )
}