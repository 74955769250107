import { CreateSvg } from '../../../static/svg/CreateSvg';
import react, { useState } from 'react';
import { Paging } from '../../common/Paging';
import { ISpam } from '../../../api/spam';
import { dateToString } from '../../../libs/date';


interface ISpamTableItemProperties {
    spam: ISpam;
}

const SpamTableItem = ({ spam }: ISpamTableItemProperties) => {
    return (
        <div className='table__row'>
            <div className='table__item email-subject'>
                { !!spam.recipient ? spam.recipient : 'all' }
            </div>
            <div className='table__item email-subject'>
                { spam.header }
            </div>
            <div className='table__item date'>
                { dateToString(spam.create_moment) }
            </div>
        </div>
    );
}


interface ISpamTableProperties {
    pageSize: number;
    spamList: ISpam[];
    onCreateRequest: () => void;
}

export const SpamTable = ({ pageSize, spamList, onCreateRequest }: ISpamTableProperties) => {
    const [page, setPage] = useState<number>(1);

    const spamListSlice = spamList
        .slice((page - 1) * pageSize, page * pageSize);

    const onCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        onCreateRequest();
    }

    return (
        <>
            <div className='mass-mailing__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item email-subject'>Recipient</div>
                    <div className='table__item email-subject'>Header</div>
                    <div className='table__item date'>Create</div>
                </div>
                <button
                    className='table__create-mass-mail create-btn popup-link'
                    onClick={onCreate}
                >
                    <CreateSvg />
                </button>
                {
                    spamListSlice.map((spam, i) => (
                        <SpamTableItem
                            key={i}
                            spam={spam}
                        />
                    ))
                }
            </div>
            <div className='mass-mailing__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(spamList.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}