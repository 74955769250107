import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { signUpStart } from '../../api/auth';
import { AlertContext } from '../../stor/AlertProvider';


export const SignUpStart = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const [email, setEmail] = useState<string>('');
    const [agree, setAgree] = useState<boolean>(false);

    const onSubmit = useCallback(async () => {
        try {
            if (!agree) {
                setAlertMessage('You cannot create an account without agreeing.');
                return;
            }

            await signUpStart(email);

            setAlertMessage('Follow the link that came to your email to continue. Please note that the letter may be identified as spam.');
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [email, agree]);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [onSubmit]);

    return (
        <section className='signup'>
            <div className='signup__container'>
                <div className='signup__item item'>
                    <h3 className='item__welcome'>
                        Sign Up
                    </h3>
                    <div className='item__wrapper'>
                        <div className='item__input email-item top-animation'>
                            <input
                                className='email-item__input'
                                type='text'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label>
                                Email
                            </label>
                        </div>
                        <div className='item__input checkbox-input'>
                            <label className='cr-wrapper'>
                                <input
                                    className='checked'
                                    type='checkbox'
                                    checked={agree}
                                    onChange={() => setAgree(!agree)}
                                />
                                <div className='cr-input' />
                                <span>
                                    I agree with platform <a href=''>terms</a>
                                </span>
                            </label>
                        </div>
                        <button
                            className='item__btn'
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            Submit
                        </button>
                        <div className='item__links links'>
                            <Link className='links__item' to='/auth/sign-in'>
                                Sign In
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}