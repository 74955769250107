import react, { useContext, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { AuthContext } from '../../../stor/AuthProvider';
import { PlotIntervalForm } from './PlotIntervalForm';
import { getStatPlot, StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../api/stat';
import { dateToString } from '../../../libs/date';
import { AlertContext } from '../../../stor/AlertProvider';


interface ILinPlotProperties {
    header: string;
    wait: boolean;
    obj: StatPlotObject;
    sub: StatPlotSubject;
    pre: StatPlotPredicat;
    objId?: string;
}

export const LinPlot = ({ header, wait, obj, sub, pre, objId }: ILinPlotProperties) => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [from, setFrom] = useState<Date>(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    const [to, setTo] = useState<Date>(new Date());

    const [data, setData] = useState<{ name: string, value: number }[]>([]);

    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        if (wait) {
            return;
        }

        const work = async () => {
            try {
                const r = await getStatPlot(obj, sub, pre, objId, from, to, user.bear);

                setData(r.data.map((x) => ({ name: dateToString(new Date(x.name)), value: x.value })));

                setInit(true);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
                return;
            }
        }

        work();
    }, [wait, objId])

    useEffect(() => {
        if (!init) {
            return;
        }

        const work = async () => {
            try {
                const r = await getStatPlot(obj, sub, pre, objId, from, to, user.bear);

                setData(r.data.map((x) => ({ name: dateToString(new Date(x.name)), value: x.value })));
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
                return;
            }
        }

        work();
    }, [init, from, to, objId]);

    return (
        <div className='charts__pie-chart'>
            <h3>
                {header}
            </h3>
            <PlotIntervalForm
                from={from}
                to={to}
                setFrom={(f) => setFrom(f)}
                setTo={(t) => setTo(t)}
            />
            <ResponsiveContainer>
                <LineChart data={data} margin={{ top: 5, right: 10, left: -20, bottom: 5 }}>
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Line dataKey='value' type='monotone' stroke='#3d3b3b' />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}