import react, { useCallback, useContext, useEffect, useRef } from 'react';
import { deleteAdvertisementModel, IAdvertisementModel } from '../../../../api/advertisement';
import { AlertContext } from '../../../../stor/AlertProvider';
import { AuthContext } from '../../../../stor/AuthProvider';
import { CloseSvg } from '../../../../static/svg/CloseSvg';


interface IDeleteAdvertisementItemUpdateWindowProperties {
    nickname: string;
    model: IAdvertisementModel;
    onDelete: (m: IAdvertisementModel) => void;
    onExit: () => void;
}

export const DeleteAdvertisementItemUpdateWindow = ({ nickname, model, onDelete, onExit }: IDeleteAdvertisementItemUpdateWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const onSubmit = useCallback(async () => {
        try {
            const a = await deleteAdvertisementModel(nickname, model.id, user.bear);

            onDelete(a);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [nickname, model, onDelete]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='archive-popup popup popup-active'>
            <div className='archive-popup__body popup__body' ref={ref}>
                <div className='archive-popup__title popup__title title__small'>
                    Delete Advertisement Model
                </div>
                <div
                    className='archive-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='archive-popup__wrapper popup__wrapper'>
                    <p className='archive-popup__text'>
                        Are you sure?
                        <br />
                        This action can't be undone!
                        <br />
                        This action will happen immediately!
                    </p>
                </div>
                <div className='archive-popup__buttons popup__buttons'>
                    <button
                        className='archive-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}