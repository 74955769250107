import React, { useContext, useEffect, useRef } from 'react';
import { AlertContext } from '../../stor/AlertProvider';
import { CloseSvg } from '../../static/svg/CloseSvg';


export const AlertWindow = () => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    useEffect(() => {
        const body = document.querySelector('body');

        const work = (e) => {
            if (!ref || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            setAlertMessage('');
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', work);

        return () => {
            document.removeEventListener('mousedown', work);
            body.classList.remove('lock');
        }
    }, [ref]);

    return (
        <div className='alert-popup popup popup-active'>
            <div className='alert-popup__body popup__body' ref={ref}>
                <div
                    className='alert-popup__close popup__close close-popup'
                    onClick={() => setAlertMessage('')}
                >
                    <CloseSvg />
                </div>
                <div className='alert-popup__wrapper popup__wrapper'>
                    <span className='alert-popup__alert'>
                        {alertMessage}
                    </span>
                </div>
            </div>
        </div>
    );
}