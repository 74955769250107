import { CreateSvg } from '../../../static/svg/CreateSvg';
import { ReadSvg } from '../../../static/svg/ReadSvg';
import { UpdateSvg } from '../../../static/svg/UpdateSvg';
import { ArchiveSvg } from '../../../static/svg/ArchiveSvg';
import react, { useContext, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { Paging } from '../Paging';
import { UserRole } from '../../../api/auth';
import { ISupportChat } from '../../../api/support';
import { dateToString } from '../../../libs/date';


interface ISupportChatTableItemProperties {
    chat: ISupportChat;
    onReadRequest: (chatId: string) => void;
    onUpdateRequest: (chatId: string) => void;
    onDeleteRequest: (chatId: string) => void;
}

const SupportChatTableItem = ({
    chat,
    onReadRequest,
    onUpdateRequest,
    onDeleteRequest
}: ISupportChatTableItemProperties) => {
    const { user } = useContext(AuthContext);

    const openMessageCount = user.role == UserRole.CLIENT ? chat.unread_root_message_number : chat.unread_user_message_number;

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                {
                    !!chat.is_archived &&
                    <button className='actions__archive' onClick={(e) => { e.preventDefault(); onReadRequest(chat.id) }}>
                        <ReadSvg />
                    </button>
                }
                {
                    !chat.is_archived &&
                    <button className='actions__archive' onClick={(e) => { e.preventDefault(); onUpdateRequest(chat.id) }}>
                        <UpdateSvg />
                    </button>
                }
                {
                    !chat.is_archived && user.role == UserRole.CLIENT &&
                    <button className='actions__archive' onClick={(e) => { e.preventDefault(); onDeleteRequest(chat.id) }}>
                        <ArchiveSvg />
                    </button>
                }
            </div>
            <div className='table__item chat-date-creation'>
                {dateToString(chat.create_moment)}
            </div>
            <div className='table__item subject'>
                {chat.header}
            </div>
            <div className='table__item new-massage'>
                { 0 < openMessageCount && <span>{openMessageCount}</span> }
            </div>
        </div>
    );
}


interface ISupportChatTableProperties {
    pageSize: number;
    createFlag: boolean;
    chatList: ISupportChat[];
    onCreateRequest: () => void;
    onReadRequest: (chatId: string) => void;
    onUpdateRequest: (chatId: string) => void;
    onDeleteRequest: (chatId: string) => void;
}

export const SupportChatTable = ({
    pageSize,
    createFlag,
    chatList,
    onCreateRequest,
    onReadRequest,
    onUpdateRequest,
    onDeleteRequest
}: ISupportChatTableProperties) => {
    const { user } = useContext(AuthContext);

    const [page, setPage] = useState<number>(1);

    const chatListSlice = chatList
        .sort((a, b) => {
            if (!a.is_archived && b.is_archived) {
                return -1;
            }
            if (a.is_archived && !b.is_archived) {
                return +1;
            }
            if (user.role == UserRole.CLIENT && a.unread_root_message_number != b.unread_root_message_number) {
                return b.unread_root_message_number - a.unread_root_message_number;
            }
            if (user.role != UserRole.CLIENT && a.unread_user_message_number != b.unread_user_message_number) {
                return b.unread_user_message_number - a.unread_user_message_number;
            }

            return b.create_moment.getTime() - a.create_moment.getTime();
        })
        .slice((page - 1) * pageSize, page * pageSize);

    return (
        <>
            <div className='support__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item chat-date-creation'>Date</div>
                    <div className='table__item subject'>Header</div>
                    <div className='table__item new-massage'>Unread</div>
                </div>
                {
                    createFlag &&
                    <button
                        className='table__create-chat create-btn popup-link'
                        onClick={(e) => { e.preventDefault(); onCreateRequest() }}
                    >
                        <CreateSvg />
                    </button>
                }
                {
                    chatListSlice
                        .map((chat) => (
                            <SupportChatTableItem
                                key={chat.id}
                                chat={chat}
                                onReadRequest={onReadRequest}
                                onUpdateRequest={onUpdateRequest}
                                onDeleteRequest={onDeleteRequest}
                            />
                        ))
                }
            </div>
            <div className='support__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(chatList.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}