import React from 'react';

export const StatusSafeSvg = () => {
    return (
        <svg className='status__confirmed' fill='#000000' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24' xmlSpace='preserve'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <path d='M19.3,5.3L9,15.6l-4.3-4.3l-1.4,1.4l5,5L9,18.4l0.7-0.7l11-11L19.3,5.3z' />
                <rect fill='none' width='24' height='24' />
            </g>
        </svg>
    );
}