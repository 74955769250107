import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';
import { AdvertisementModelView } from '../../advertisement/common/AdvertisementModelView';
import { AdvertisementModelStatus, IAdvertisement, IAdvertisementModel } from '../../../api/advertisement';
import { updateAdvertisementModelForModeration } from '../../../api/moderation';
import { AlertContext } from '../../../stor/AlertProvider';


interface IUpdateRootModerationAdvertisementModelWindowProperties {
    advertisement: IAdvertisement;
    model: IAdvertisementModel;
    onUpdate: (advertisement: IAdvertisement, model: IAdvertisementModel) => void;
    onExit: () => void;
}

export const UpdateRootModerationAdvertisementModelWindow = ({ advertisement, model, onUpdate, onExit }: IUpdateRootModerationAdvertisementModelWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const onSubmit = useCallback(async (status: AdvertisementModelStatus.SAFE | AdvertisementModelStatus.UNSAFE) => {
        try {
            const am = await updateAdvertisementModelForModeration(advertisement, model.id, status, user.bear);
            onUpdate(advertisement, am);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [advertisement, model, onUpdate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit(AdvertisementModelStatus.SAFE);
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='view-card popup popup-active'>
            <div className='view-card__body popup__body' ref={ref}>
                <div
                    className='view-card__close  popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='view-cadr__wrapper popup__wrapper'>
                    <div className='view-card__presentation presentation'>
                        <AdvertisementModelView
                            advertisement={advertisement}
                            model={model}
                        />
                    </div>
                </div>
                <div className='view-card popup__buttons popup__buttons'>
                    <button
                        className='view-card popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit(AdvertisementModelStatus.SAFE)
                        }}
                    >
                        Accept
                    </button>
                    <button
                        className='view-card popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit(AdvertisementModelStatus.UNSAFE)
                        }}
                    >
                        Reject
                    </button>
                </div>
            </div>
        </div>
    );
}