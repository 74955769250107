import react, { useState } from 'react';
import { IAdvertisement, IAdvertisementModel } from '../../../api/advertisement';
import { UpdateSvg } from '../../../static/svg/UpdateSvg';
import { Paging } from '../../common/Paging';
import { dateToString } from '../../../libs/date';


interface IRootModerationAdvertisementModelListItemProperties {
    advertisement: IAdvertisement;
    model: IAdvertisementModel;
    onUpdateRequest: (a: IAdvertisement, m: IAdvertisementModel) => void;
}

const RootModerationAdvertisementModelListItem = ({advertisement, model, onUpdateRequest}: IRootModerationAdvertisementModelListItemProperties) => {
    const onUpdate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onUpdateRequest(advertisement, model);
    }

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button className='actions__edit popup-link' onClick={onUpdate}>
                    <UpdateSvg />
                </button>
            </div>
            <div className='table__item avatar'>
                <img src={model.avatar} />
            </div>
            <div className='table__item spent'>
                {advertisement.nickname}
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(model.create_moment))}
            </div>
            <div className='table__item create-date'>
                {dateToString(new Date(model.update_moment))}
            </div>
        </div>
    );
}

interface IRootModerationAdvertisementModelListProperties {
    advertisementList: IAdvertisement[];
    onUpdateRequest: (a: IAdvertisement, m: IAdvertisementModel) => void;
}

export const RootModerationAdvertisementModelList = ({advertisementList, onUpdateRequest}: IRootModerationAdvertisementModelListProperties) => {
    const pageSize = 10;
    const size = advertisementList.reduce((x, a) => x + a.model_list.length, 0);

    const [page, setPage] = useState<number>(1);

    let list: [IAdvertisement, IAdvertisementModel][] = [];
    advertisementList.forEach((a) => a.model_list.forEach((m) => list.push([a, m])));
    list = list
        .sort((a, b) => a[1].update_moment.getTime() - b[1].update_moment.getTime())
        .slice((page - 1) * pageSize, page * pageSize);

    return (
        <>
            <div className='advertisement__table table'>
                <div className='table__row row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item avatar'>Avatar</div>
                    <div className='table__item spent'>Nickname</div>
                    <div className='table__item create-date'>Create</div>
                    <div className='table__item create-date'>Update</div>
                </div>
                {
                    list.map((e) => (
                        <RootModerationAdvertisementModelListItem
                            key={e[1].id}
                            advertisement={e[0]}
                            model={e[1]}
                            onUpdateRequest={onUpdateRequest}
                        />
                    ))
                }
            </div>
            <div className='advertisement__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(size / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}