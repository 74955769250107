import react, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { PlotIntervalForm } from './PlotIntervalForm';
import { ISuccessPayment } from '../../../api/payment';
import { dateToString } from '../../../libs/date';


interface IDepositLByDateProperties {
    depositList: ISuccessPayment[];
}

export const DepositLByDate = ({ depositList }: IDepositLByDateProperties) => {
    const [from, setFrom] = useState<Date>(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    const [to, setTo] = useState<Date>(new Date());

    const [data, setData] = useState<{ name: string, value: number }[]>([]);

    useEffect(() => {
        if (depositList.length == 0) {
            return;
        }

        let step = 15 * 60 * 1000 * Math.ceil(Math.log2((to.getTime() - from.getTime()) / (2 * 24 * 60 * 60 * 1000)));
        if (step < 15 * 60 * 1000) {
            step = 15 * 60 * 1000;
        }

        const dataList: { name: string, value: number }[] = [];

        let [start, i] = [from, 0];

        dataList.push(({
            name: dateToString(start),
            value: 0,
        }));

        const currDepositList = depositList.filter((deposit) => from.getTime() <= deposit.moment.getTime() && deposit.moment.getTime() < to.getTime());

        while (start.getTime() < to.getTime()) {
            while (i < currDepositList.length && currDepositList[i].moment.getTime() < start.getTime() + step) {
                dataList[dataList.length - 1].value += Number(currDepositList[i].amount);

                i += 1;
            }

            start = new Date(start.getTime() + step);

            dataList.push({
                name: dateToString(start),
                value: 0,
            });
        }

        setData(dataList);
    }, [depositList, from, to]);

    return (
        <div className='charts__pie-chart'>
            <h3>
                Deposits By Date
            </h3>
            <PlotIntervalForm
                from={from}
                to={to}
                setFrom={(f) => setFrom(f)}
                setTo={(t) => setTo(t)}
            />
            <ResponsiveContainer>
                <LineChart data={data} margin={{ top: 5, right: 10, left: -20, bottom: 5 }}>
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Line dataKey='value' type='monotone' stroke='#3d3b3b' />
                </LineChart>
            </ResponsiveContainer>
        </div>

    );
}