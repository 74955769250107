import React from 'react';

export const ClockSvg = () => {
    return (
        <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
            <g id='SVGRepo_iconCarrier'>
                <path d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' stroke='#ffffff' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M12 6V12' stroke='#ffffff' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M16.24 16.24L12 12' stroke='#ffffff' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            </g>
        </svg>
    );
}