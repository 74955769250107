import react, { useCallback, useContext, useEffect, useRef } from 'react';
import { IModer, deleteModer } from '../../../api/moder';
import { AuthContext } from '../../../stor/AuthProvider';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface DeleteRootModerWindowProperties {
    moder: IModer;
    onSuccess: (moder: IModer) => void;
    onExit: () => void;
}

export const DeleteRootModerWindow = ({ moder, onSuccess, onExit }: DeleteRootModerWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const onSubmit = useCallback(async () => {
        try {
            const m = await deleteModer(moder.id, user.bear);
            onSuccess(m);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [moder, onSuccess]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='delete-popup popup popup-active'>
            <div className='delete-popup__body popup__body' ref={ref}>
                <h3 className='delete-popup__title popup__title title__small'>
                    Delete Moder
                </h3>
                <div
                    className='delete-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='delete-popup__wrapper popup__wrapper'>
                    <p className='delete-popup__text'>
                        Are you sure?
                        <br />
                        This action can't be undone!
                    </p>
                </div>
                <div className='delete-popup__buttons popup__buttons'>
                    <button
                        className='delete-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}