import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../stor/AuthProvider';
import { UserRole } from '../../../api/auth';
import { ISupportChat, ISupportChatMessage, getSupportMessageList } from '../../../api/support';
import { dateToString } from '../../../libs/date';
import { AlertContext } from '../../../stor/AlertProvider';
import { SupportContext } from '../../../stor/SupportProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ISupportChatWindowProperties {
    createFlag: boolean;
    chat: ISupportChat;
    onExit: () => void;
}

export const SupportChatWindow = ({ createFlag, chat, onExit }: ISupportChatWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const { supportData, readChat, createChatMessage } = useContext(SupportContext);

    const [messageList, setMessageList] = useState<ISupportChatMessage[]>([]);

    const [body, setBody] = useState<string>('');

    const onSubmit = useCallback(() => {
        try {
            createChatMessage(chat.id, body);
            setBody('');
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [chat, body]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!createFlag || !ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [createFlag, ref, alertMessage, onSubmit]);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getSupportMessageList(chat.id, user.bear);
                setMessageList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    useEffect(() => {
        if (messageList.length == 0) {
            return;
        }

        try {
            readChat(chat.id);
        }
        catch (error) {
            console.error(error);
        }
    }, [messageList]);

    useEffect(() => {
        if (!supportData) {
            return;
        }

        const onCreateMessage = (nextChat: ISupportChat, nextMessage: ISupportChatMessage) => {
            nextMessage.create_moment = new Date();

            if (chat.id != nextChat.id) {
                return;
            }

            setMessageList([...messageList, nextMessage]);
        }

        const onArchiveChat = (nextChat: ISupportChat) => {
            if (chat.id != nextChat.id) {
                return;
            }

            onExit();
        }

        supportData.socket.on('create-message', onCreateMessage);
        supportData.socket.on('archive-chat', onArchiveChat);

        return () => {
            supportData.socket.off('create-message', onCreateMessage);
            supportData.socket.off('archive-chat', onArchiveChat);
        }
    }, [supportData, messageList]);

    const messageClass = (message: ISupportChatMessage) => {
        if (user.role == UserRole.CLIENT && message.user_id == chat.user_id) {
            return 'chat-list__message message message__mine';
        }
        if (user.role == UserRole.CLIENT && message.user_id != chat.user_id) {
            return 'chat-list__message message message__not-mine';
        }
        if (user.role != UserRole.CLIENT && message.user_id == chat.user_id) {
            return 'chat-list__message message message__not-mine';
        }
        if (user.role != UserRole.CLIENT && message.user_id != chat.user_id) {
            return 'chat-list__message message message__mine';
        }
    }

    return (
        <div className='chat-popup popup popup-active'>
            <div className='chat-popup__body popup__body' ref={ref}>
                <h3 className='chat-popup__subject popup__title title__small'>
                    {chat.header}
                </h3>
                <div
                    className='chat-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='chat-popup__wrapper popup__wrapper'>
                    <div className='chat-popup__chat chat-list'>
                        {
                            messageList.map((message) => {
                                return (
                                    <div
                                        key={message.create_moment.getTime()}
                                        className={messageClass(message)}
                                    >
                                        <p className='message__text'>
                                            {message.body}
                                        </p>
                                        <p className='message__date'>
                                            {dateToString(message.create_moment)}
                                        </p>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        createFlag &&
                        <div className='chat-popup__send send-text'>
                            <input
                                className='send-text__input'
                                type='text'
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                            />
                            <button
                                className='send-text__send-btn'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                            >
                                <svg viewBox='0 -0.5 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                                    <g id='SVGRepo_iconCarrier'>
                                        <path fillRule='evenodd' clipRule='evenodd' d='M18.455 9.8834L7.063 4.1434C6.76535 3.96928 6.40109 3.95274 6.08888 4.09916C5.77667 4.24558 5.55647 4.53621 5.5 4.8764C5.5039 4.98942 5.53114 5.10041 5.58 5.2024L7.749 10.4424C7.85786 10.7903 7.91711 11.1519 7.925 11.5164C7.91714 11.8809 7.85789 12.2425 7.749 12.5904L5.58 17.8304C5.53114 17.9324 5.5039 18.0434 5.5 18.1564C5.55687 18.4961 5.77703 18.7862 6.0889 18.9323C6.40078 19.0785 6.76456 19.062 7.062 18.8884L18.455 13.1484C19.0903 12.8533 19.4967 12.2164 19.4967 11.5159C19.4967 10.8154 19.0903 10.1785 18.455 9.8834V9.8834Z' stroke='#000000' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}