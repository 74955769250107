import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ICreateModelDTO, IModel, createModel, getModel, isModelExist } from '../../../api/model';
import { AuthContext } from '../../../stor/AuthProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';
import { ModelEdit } from '../../common/model/ModelEdit';
import { ModelView } from '../../common/model/ModelView';
import { AlertContext } from '../../../stor/AlertProvider';


interface ICreateRootModelFProperties {
    onCreate: (nickname: string, model: IModel) => void;
    onExit: () => void;
}

const CreateRootModelF = ({ onCreate, onExit }: ICreateRootModelFProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [nickname, setNickname] = useState<string>('');

    const onSubmit = useCallback(async () => {
        try {
            let nm: string = '';

            try {
                new URL(nickname);

                const r = nickname.split('/');
                while (0 < r.length && nm.length == 0) {
                    nm = r.pop();
                }
            }
            catch {
                nm = (nickname.charAt(0) != '@')
                    ? nickname
                    : nickname.slice(1, nickname.length);
            }

            const m = await getModel(nm, user.bear);

            const f = await isModelExist(m.nickname, user.bear);
            if (f) {
                throw new Error('This model already exist.');
            }

            onCreate(nm, m);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [nickname, onCreate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-add-popup popup popup-active'>
            <div className='create-add-popup__body popup__body' ref={ref}>
                <div className='create-add-popup__title popup__title title__small'>
                    Create Model
                </div>
                <div
                    className='create-add-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-add-popup__wrapper popup__wrapper'>
                    <div className='create-add-popup__input top-animation'>
                        <input
                            type='text'
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                        />
                        <label>
                            OnlyFans Account Nickname
                        </label>
                    </div>
                </div>
                <div className='create-add-popup__buttons popup__buttons'>
                    <button
                        className='create-add-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

interface ICreateRootModelSProperties {
    inp: IModel;
    onCreate: (model: IModel) => void;
    onExit: () => void;
}

const CreateRootModelS = ({ inp, onCreate, onExit }: ICreateRootModelSProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [model, setModel] = useState<IModel>(inp);

    const onSubmit = useCallback(async () => {
        try {
            const dto: ICreateModelDTO = {
                nickname: model.nickname,
                name: model.name,
                about: model.about,
                avatar: model.avatar,
                like_count: model.like_count,
                photo_count: model.photo_count,
                video_count: model.video_count,
                subscribe_cost: model.subscribe_cost,
                gender: model.gender,
                categories: model.categories,
            };

            const r = await createModel(dto, user.bear);
            onCreate(r);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [model, onCreate]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-adv-admin popup popup-active'>
            <div className='create-adv-admin__body popup__body' ref={ref}>
                <h3 className='create-adv-admin__title popup__title title__small'>
                    Create Model
                </h3>
                <div
                    className='create-adv-admin__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-adv-admin__wrapper popup__wrapper'>
                    <div className='create-adv-admin__presentation presentation'>
                        <ModelView
                            model={model}
                        />
                    </div>
                    <div className='create-adv-admin__data data-create-view'>
                        <div className='data-create-view__body'>
                            <ModelEdit
                                model={model}
                                setModel={(m) => setModel(m)}
                            />
                            <button
                                className='create-adv-admin__submit'
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ICreateRootModelWindowProperties {
    onCreate: (model: IModel) => void;
    onExit: () => void;
}

export const CreateRootModelWindow = ({ onCreate, onExit }: ICreateRootModelWindowProperties) => {
    const [model, setModel] = useState<IModel>();

    if (!model) {
        return <CreateRootModelF onCreate={(n, m) => setModel(m)} onExit={onExit} />;
    }

    return <CreateRootModelS inp={model} onCreate={onCreate} onExit={onExit} />;
}