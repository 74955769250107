const fileConfList = [
    {
        signature: '424D',
        extensionList: ['bmp', 'dib'],
        mime: 'image/bmp',
    },
    {
        signature: '01000000',
        extensionList: ['emf'],
        mime: 'image/emf',
    },
    {
        signature: '53494D504C4520203D202020202020202020202020202020202020202054',
        extensionList: ['fits'],
        mime: 'image/fits',
    },
    {
        signature: '474946383961',
        extensionList: ['gif'],
        mime: 'image/gif',
    },
    {
        signature: '0000000C6A5020200D0A',
        extensionList: ['jp2'],
        mime: 'image/jp2',
    },
    {
        signature: 'FFD8',
        extensionList: ['jpeg', 'jpg', 'jpe', 'jfif'],
        mime: 'image/jpeg',
    },
    {
        signature: '89504E470D0A1A0A',
        extensionList: ['png'],
        mime: 'image/png',
    },
    {
        signature: '492049',
        extensionList: ['tif', 'tiff'],
        mime: 'image/tiff',
    },
    {
        signature: '49492A00',
        extensionList: ['tif', 'tiff'],
        mime: 'image/tiff',
    },
    {
        signature: '4D4D002A',
        extensionList: ['tif', 'tiff'],
        mime: 'image/tiff',
    },
    {
        signature: '4D4D002B',
        extensionList: ['tif', 'tiff'],
        mime: 'image/tiff',
    },
    {
        signature: '38425053',
        extensionList: ['psd'],
        mime: 'image/vnd.adobe.photoshop',
    },
    {
        signature: '41433130',
        extensionList: ['dwg'],
        mime: 'image/vnd.dwg',
    },
    {
        signature: '00000100',
        extensionList: ['ico'],
        mime: 'image/vnd.microsoft.icon',
    },
    {
        signature: '4550',
        extensionList: ['mdi'],
        mime: 'image/vnd.ms-modi',
    },
    {
        signature: '233F52414449414E43450A',
        extensionList: ['hdr'],
        mime: 'image/vnd.radiance',
    },
    {
        signature: 'D7CDC69A',
        extensionList: ['wmf'],
        mime: 'image/wmf',
    },
    {
        signature: '52494646',
        extensionList: ['webp'],
        mime: 'image/webp',
    },
    {
        signature: '50350A',
        extensionList: ['pgm'],
        mime: 'image/x-portable-graymap',
    },
    {
        signature: '01DA01010003',
        extensionList: ['rgb'],
        mime: 'image/x-rgb',
    },
];

const maxSignatureSize = Math.max(...fileConfList.map((conf) => conf.signature.length / 2));

export const checkImageFormat = async (file: File): Promise<undefined | string> => {
    try {
        if (!file.type) {
            return `The format of your file is not recognized by the browser, check if you have changed the format in the file name.`;
        }

        const signatureBuffer = await file.slice(0, Math.min(file.size, maxSignatureSize)).arrayBuffer();
        const signature = (new Uint8Array(signatureBuffer)).reduce<string>((x, y) => x + y.toString(16).toUpperCase(), '');

        let fileConf = undefined;
        for (const conf of fileConfList) {
            if (signature.length < conf.signature.length) {
                continue;
            }

            if (signature.slice(0, conf.signature.length) == conf.signature) {
                fileConf = conf;
                break;
            }
        }

        if (!fileConf) {
            return `The format of your file was not recognized as valid. This may be because the file is corrupted or it is not a common format.`;
        }
        else if (fileConf.mime != file.type) {
            return `The actual format of your file is not the one indicated in its extension. Real format - ${fileConf.extensionList[0]}, specified - ${file.type.split('/')[file.type.split('/').length - 1]}.`;
        }

        return undefined;
    }
    catch (error) {
        console.error(error);
        return `Something went wrong while detecting your file format, try using a different one.`;
    }
}