import react, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../stor/AuthProvider';
import { AdvertisementStat } from './AdvertisementStat';
import { AdvertisementTable } from './AdvertisementTable';
import { CreateAdvertisementWindow } from './CreateAdvertisementWindow';
import { UserRole } from '../../../api/auth';
import { getAdvertisementList, IAdvertisement } from '../../../api/advertisement';
import { LinPlot } from '../../common/plot/LinPlot';
import { PiePlot } from '../../common/plot/PiePlot';
import { StatPlotObject, StatPlotPredicat, StatPlotSubject } from '../../../api/stat';
import { AlertContext } from '../../../stor/AlertProvider';
import { ArchiveAdvertisementWindow } from './ArchiveAdvertisementWindow';
import { SwitchAdvertisementWindow } from './SwitchAdvertisementWindow';
import { RefreshAdvertisementWindow } from './RefreshAdvertisementWindow';
import { DeleteAdvertisementWindow } from './DeleteAdvertisementWindow';


interface IRequest {
    method: 'CREATE' | 'SWITCH' | 'REFRESH' | 'ARCHIVE' | 'DELETE';
    advertisement?: IAdvertisement;
}

export const AdvertisementList = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [advertisementList, setAvertisementList] = useState<IAdvertisement[]>([]);

    const [request, setRequest] = useState<IRequest>();

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getAdvertisementList(user.bear);

                setAvertisementList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    const onReadRequest = (a: IAdvertisement) => {
        navigate(`/${user.role == UserRole.CLIENT ? 'user' : 'root'}/advertisement/read?nickname=${a.nickname}`);
    }

    const onUpdateRequest = (a: IAdvertisement) => {
        navigate(`/${user.role == UserRole.CLIENT ? 'user' : 'root'}/advertisement/update?nickname=${a.nickname}`);
    }

    return (
        <>
            <section className='advertisement'>
                <div className='advertisement__container'>
                    <h2 className='advertisement__title title'>
                        Advertisement List
                    </h2>
                    <AdvertisementStat advertisementList={advertisementList} />
                    <div className='advertisement__charts charts'>
                        <LinPlot
                            header={'Clicks By Date'}
                            wait={advertisementList.length == 0}
                            obj={StatPlotObject.USER}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_MOMENT}
                            objId={0 < advertisementList.length ? advertisementList[0].user_id : undefined}
                        />
                        <PiePlot
                            header={'Clicks By Region'}
                            wait={advertisementList.length == 0}
                            obj={StatPlotObject.USER}
                            sub={StatPlotSubject.PICK}
                            pre={StatPlotPredicat.COUNT_BY_REGION}
                            objId={0 < advertisementList.length ? advertisementList[0].user_id : undefined}
                        />
                    </div>
                    <AdvertisementTable
                        advertisementList={advertisementList}
                        onCreateRequest={() => setRequest({ method: 'CREATE' })}
                        onReadRequest={onReadRequest}
                        onUpdateRequest={onUpdateRequest}
                        onSwitchRequest={(a) => setRequest({ method: 'SWITCH', advertisement: a })}
                        onRefreshRequest={(a) => setRequest({ method: 'REFRESH', advertisement: a })}
                        onArchiveRequest={(a) => setRequest({ method: 'ARCHIVE', advertisement: a })}
                        onDeleteRequest={(a) => setRequest({ method: 'DELETE', advertisement: a })}
                    />
                </div>
            </section>
            {
                !!request && request.method == 'CREATE' &&
                <CreateAdvertisementWindow onExit={() => setRequest(null)} />
            }
            {
                !!request && request.method == 'SWITCH' &&
                <SwitchAdvertisementWindow
                    advertisement={request.advertisement}
                    onSwitch={(a) => {
                        setAvertisementList(advertisementList.map((aa) => aa.nickname != a.nickname ? aa : a));
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'REFRESH' &&
                <RefreshAdvertisementWindow
                    advertisement={request.advertisement}
                    onRefresh={(a) => {
                        setAvertisementList(advertisementList.map((aa) => aa.nickname != a.nickname ? aa : a));
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'ARCHIVE' &&
                <ArchiveAdvertisementWindow
                    advertisement={request.advertisement}
                    onArchive={(a) => {
                        setAvertisementList(advertisementList.map((aa) => aa.nickname != a.nickname ? aa : a));
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
                        {
                !!request && request.method == 'DELETE' &&
                <DeleteAdvertisementWindow
                    advertisement={request.advertisement}
                    onDelete={(a) => {
                        setAvertisementList(advertisementList.filter((aa) => aa.nickname != a.nickname));
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}