import { CreateSvg } from '../../../static/svg/CreateSvg';
import { ReadSvg } from '../../../static/svg/ReadSvg';
import { UpdateSvg } from '../../../static/svg/UpdateSvg';
import { PauseSvg } from '../../../static/svg/PauseSvg';
import { PlaySvg } from '../../../static/svg/PlaySvg';
import { ArchiveSvg } from '../../../static/svg/ArchiveSvg';
import react, { useContext, useState } from 'react';
import { Paging } from '../../common/Paging';
import { IAdvertisement } from '../../../api/advertisement';
import { dateToString } from '../../../libs/date';
import { AuthContext } from '../../../stor/AuthProvider';
import { UserRole } from '../../../api/auth';
import { RefreshSvg } from '../../../static/svg/RefreshSvg';
import { DeleteSvg } from '../../../static/svg/DeleteSvg';


interface IAdvertisementTableItemProperties {
    advertisement: IAdvertisement;
    onReadRequest: (a: IAdvertisement) => void;
    onUpdateRequest: (a: IAdvertisement) => void;
    onSwitchRequest: (a: IAdvertisement) => void;
    onRefreshRequest: (a: IAdvertisement) => void;
    onArchiveRequest: (a: IAdvertisement) => void;
    onDeleteRequest: (a: IAdvertisement) => void;
}

const AdvertisementTableItem = ({
    advertisement,
    onReadRequest,
    onUpdateRequest,
    onSwitchRequest,
    onRefreshRequest,
    onArchiveRequest,
    onDeleteRequest
}: IAdvertisementTableItemProperties) => {
    const { user } = useContext(AuthContext);

    const clickCount = advertisement.model_list.reduce((y, m) => y + m.click_count_today, 0);

    const totalClickCount = advertisement.model_list.reduce((y, m) => y + m.click_count, 0);

    const spent = advertisement.model_list.reduce((y, m) => y + m.spent_count, 0);

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button
                    className='actions__view'
                    onClick={(e) => { e.preventDefault; onReadRequest(advertisement); }}
                >
                    <ReadSvg />
                </button>
                {
                    !advertisement.is_delete &&
                    <button
                        className='actions__edit'
                        onClick={(e) => { e.preventDefault; onUpdateRequest(advertisement); }}
                    >
                        <UpdateSvg />
                    </button>
                }
                {
                    !advertisement.is_delete && advertisement.is_active &&
                    <button
                        className='actions__pause-play pause-play active'
                        onClick={(e) => { e.preventDefault; onSwitchRequest(advertisement); }}
                    >
                        <PauseSvg />
                    </button>
                }
                {
                    !advertisement.is_delete && !advertisement.is_active &&
                    <button
                        className='actions__pause-play pause-play'
                        onClick={(e) => { e.preventDefault; onSwitchRequest(advertisement); }}
                    >
                        <PlaySvg />
                    </button>
                }
                {
                    user.role != UserRole.CLIENT &&
                    <button
                        className='actions__archive popup-link'
                        onClick={(e) => { e.preventDefault; onRefreshRequest(advertisement); }}
                    >
                        <RefreshSvg />
                    </button>
                }
                {
                    !advertisement.is_delete &&
                    <button
                        className='actions__archive popup-link'
                        onClick={(e) => { e.preventDefault; onArchiveRequest(advertisement); }}
                    >
                        <ArchiveSvg />
                    </button>
                }
                {
                    user.role != UserRole.CLIENT &&
                    <button
                        className='actions__archive popup-link'
                        onClick={(e) => { e.preventDefault; onDeleteRequest(advertisement); }}
                    >
                        <DeleteSvg />
                    </button>
                }
            </div>
            <div className='table__item avatar'><img src={advertisement.avatar} /></div>
            <div className='table__item nickname'>{advertisement.nickname}</div>
            <div className='table__item create-date'>{dateToString(advertisement.create_moment)}</div>
            <div className='table__item clicks-total'>{totalClickCount}</div>
            <div className='table__item clicks'>{clickCount}</div>
            <div className='table__item cpc'>{advertisement.click_cost}</div>
            <div className='table__item budget'>{advertisement.daily_budget}</div>
            <div className='table__item spent'>{spent.toFixed(2)} $</div>
        </div>
    );
}


interface IAdvertisementTableProperties {
    advertisementList: IAdvertisement[];
    onCreateRequest: () => void;
    onReadRequest: (a: IAdvertisement) => void;
    onUpdateRequest: (a: IAdvertisement) => void;
    onSwitchRequest: (a: IAdvertisement) => void;
    onRefreshRequest: (a: IAdvertisement) => void;
    onArchiveRequest: (a: IAdvertisement) => void;
    onDeleteRequest: (a: IAdvertisement) => void;
}

export const AdvertisementTable = ({
    advertisementList,
    onCreateRequest,
    onReadRequest,
    onUpdateRequest,
    onSwitchRequest,
    onRefreshRequest,
    onArchiveRequest,
    onDeleteRequest
}: IAdvertisementTableProperties) => {
    const pageSize = 10;

    const [page, setPage] = useState<number>(1);

    return (
        <>
            <div className='advertisement__table table'>
                <div className='table__row  row-table-first'>
                    <div className='table__item actions'>Actions</div>
                    <div className='table__item avatar'>Avatar</div>
                    <div className='table__item nickname'>Nickname</div>
                    <div className='table__item create-date'>Create</div>
                    <div className='table__item clicks-total'>Clicks</div>
                    <div className='table__item clicks'>ClicksToday</div>
                    <div className='table__item cpc'>CPC</div>
                    <div className='table__item budget'>Budget</div>
                    <div className='table__item spent'>Spent</div>
                </div>
                <button
                    className='table__create-adv create-btn popup-link'
                    onClick={(e) => { e.preventDefault(); onCreateRequest(); }}
                >
                    <CreateSvg />
                </button>
                {
                    advertisementList
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((a) => (
                            <AdvertisementTableItem
                                key={a.nickname}
                                advertisement={a}
                                onReadRequest={onReadRequest}
                                onUpdateRequest={onUpdateRequest}
                                onSwitchRequest={onSwitchRequest}
                                onRefreshRequest={onRefreshRequest}
                                onArchiveRequest={onArchiveRequest}
                                onDeleteRequest={onDeleteRequest}
                            />
                        ))
                }
            </div>
            <div className='advertisement__paging paging'>
                <Paging
                    page={page}
                    size={Math.ceil(advertisementList.length / pageSize)}
                    setPage={(p) => setPage(p)}
                />
            </div>
        </>
    );
}