import { server } from '.';


export interface IWaitPayment {
    amount: string;
    token_amount: string;
    recipient_address: string;
    recipient_qr_code_url: string;
    moment: Date;
    expiration_timestamp: number;
}

export interface ISuccessPayment {
    transaction_hash: string;
    amount: string;
    moment: Date;
}

interface ICreatePaymentResponse extends IWaitPayment {}

interface IReadPaymentStatusResponse {
    status: 'NONE' | 'WAIT' | 'DONE';
}

interface IReadPaymentsResponse {
    success: ISuccessPayment[];
    wait?: IWaitPayment;
}

export const createWaitPayment = async (amount: number, bear: string): Promise<IWaitPayment> => {
    const r = await fetch(
        `${server}/payment`,
        {
            method: 'POST',
            body: JSON.stringify({request_amount: amount}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as ICreatePaymentResponse;
    res.moment = new Date(res.moment);
    return res;
}

export const getPaymentStatus = async (moment: Date, bear: string): Promise<'NONE' | 'WAIT' | 'DONE'> => {
    const r = await fetch(
        `${server}/payment/status?moment=${moment.toISOString()}`,
        {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IReadPaymentStatusResponse;
    return res.status;
}

export const getPayments = async (bear: string): Promise<[ISuccessPayment[], IWaitPayment | null]> => {
    const r = await fetch(
        `${server}/payment`,
        {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const res = await r.json() as IReadPaymentsResponse;

    res.success.forEach((p) => p.moment = new Date(p.moment));
    if (!!res.wait) {
        res.wait.moment = new Date(res.wait.moment);
    }

    return [res.success, !!res.wait ? res.wait: null];
}

export const deleteWaitPayment = async (bear: string) => {
    const r = await fetch(
        `${server}/payment`,
        {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }
}

export const createSyntheticPayment = async (userId: string, requestAmount: number, bear: string): Promise<ISuccessPayment> => {
    const r = await fetch(
        `${server}/payment/synthetic`,
        {
            method: 'POST',
            body: JSON.stringify({ user_id: userId, request_amount: requestAmount }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const syntheticPayment = await r.json() as ISuccessPayment;

    syntheticPayment.moment = new Date(syntheticPayment.moment);

    return syntheticPayment;
}