import react from 'react';
import { IAdvertisement } from '../../../../api/advertisement';


interface IAdvertisementItemReadStatProperties {
    item: IAdvertisement;
}

export const AdvertisementItemReadStat = ({ item }: IAdvertisementItemReadStatProperties) => {
    const active = item.model_list.reduce((x, m) => x + ((!m.is_delete && m.is_active) ? 1 : 0), 0);
    const onPause = item.model_list.reduce((x, m) => x + ((!m.is_delete && !m.is_active) ? 1 : 0), 0);
    const onArchive = item.model_list.reduce((x, m) => x + (m.is_delete ? 1 : 0), 0);

    const spent = item.model_list.reduce((x, m) => x + m.spent_count, 0);
    
    const clickCount = item.model_list.reduce((x, m) => x + m.click_count, 0);

    const dailyBudget = item.daily_budget;

    const clickCost = item.click_cost;

    return (
        <div className='advertisement-view__stat stat'>
            <div className='stat__item'>
                <span className='stat__title'>Active/OnPause/OnArchive</span>
                <span className='stat__amount'>{active}/{onPause}/{onArchive}</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>Spent/ClickCount</span>
                <span className='stat__amount'>{spent.toFixed(2)} $/{clickCount}</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>DailyBudget</span>
                <span className='stat__amount'>{dailyBudget} $</span>
            </div>
            <div className='stat__item'>
                <span className='stat__title'>ClickCost</span>
                <span className='stat__amount'>{clickCost} $</span>
            </div>
        </div>
    );
}