import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IModer, createModer } from '../../../api/moder';
import { AuthContext } from '../../../stor/AuthProvider';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ICreateRootModerWindowProperties {
    onSuccess: (moder: IModer) => void;
    onExit: () => void;
}

export const CreateRootModerWindow = ({ onSuccess, onExit }: ICreateRootModerWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const [email, setEmail] = useState<string>('');

    const [showPasswordF, setShowPasswordF] = useState<boolean>(false);
    const [passwordF, setPasswordF] = useState<string>('');

    const [showPasswordS, setShowPasswordS] = useState<boolean>(false);
    const [passwordS, setPasswordS] = useState<string>('');

    const onSubmit = useCallback(async () => {
        try {
            if (passwordF != passwordS) {
                setAlertMessage('Password does not match.');
                return;
            }

            const r = await createModer(email, passwordF, user.bear);
            onSuccess(r);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [email, passwordF, passwordS, onSuccess]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-moderator popup popup-active'>
            <div className='create-moderator__body popup__body' ref={ref}>
                <h3 className='create-moderator__title popup__title title__small'>
                    Create Moder
                </h3>
                <div
                    className='create-moderator__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-moderator__wrapper popup__wrapper'>
                    <div className='create-moderator__input top-animation'>
                        <input
                            type='text'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>
                            Email
                        </label>
                    </div>
                    <div className='create-moderator__input password-item top-animation'>
                        <input
                            className='password-item__input'
                            type={showPasswordF ? 'text' : 'password'}
                            value={passwordF}
                            onChange={(e) => setPasswordF(e.target.value)}
                        />
                        <label>
                            Password
                        </label>
                        <svg
                            className='password-item__image'
                            viewBox='0 0 1024 1024'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#000000'
                            onClick={() => setShowPasswordF(!showPasswordF)}
                        >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                            <g id='SVGRepo_iconCarrier'>
                                <path fill='#000000' d='M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z' />
                            </g>
                        </svg>
                    </div>
                    <div className='create-moderator__input password-item top-animation'>
                        <input
                            className='password-item__input'
                            type={showPasswordS ? 'text' : 'password'}
                            value={passwordS}
                            onChange={(e) => setPasswordS(e.target.value)}
                        />
                        <label>
                            Confirm Password
                        </label>
                        <svg
                            className='password-item__image'
                            viewBox='0 0 1024 1024'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='#000000'
                            onClick={() => setShowPasswordS(!showPasswordS)}
                        >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' />
                            <g id='SVGRepo_iconCarrier'>
                                <path fill='#000000' d='M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z' />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='create-moderator__buttons popup__buttons'>
                    <button
                        className='create-moderator__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}