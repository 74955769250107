import imageCompression from 'browser-image-compression';
import { server } from '.';
import { Gender } from './profile';


export enum AdvertisementModelStatus {
    WAIT = 'wait',
    SAFE = 'safe',
    UNSAFE = 'unsafe',
    NONE = 'none',
}

export interface IAdvertisementModel {
    id: string;
    name: string;
    about: string;
    apath?: string;
    trial?: string;
    moderation_status: AdvertisementModelStatus;
    is_active: boolean;
    is_delete: boolean;
    visit_count: number;
    visit_count_today: number;
    view_count: number;
    view_count_today: number;
    click_count: number;
    click_count_today: number;
    spent_count: number;
    spent_count_today: number;
    create_moment: Date;
    update_moment: Date;
    avatar: string;
    destination_url: string;
    avatar_file?: File;
}

export interface IAdvertisement {
    nickname: string;
    name: string;
    about: string;
    avatar: string;
    like_count: number;
    photo_count: number;
    video_count: number;
    subscribe_cost: string;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    user_id: string;
    daily_budget: string;
    click_cost: string;
    is_active: boolean;
    is_delete: boolean;
    is_incorrect: boolean;
    create_moment: Date;
    update_moment: Date;
    model_list: IAdvertisementModel[];
}

export interface ICreateAdvertisementModelDTO {
    avatar: File;
    name: string;
    about: string;
    apath?: string;
    trial?: string;
}

export interface ICreateAdvertisementDTO {
    nickname: string;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    daily_budget: number;
    click_cost: number;
    create_model_list: ICreateAdvertisementModelDTO[];
}

export interface IUpdateAdvertisementModelDTO {
    avatar?: File;
    id: string;
    name: string;
    about: string;
    apath?: string;
    trial?: string;
    is_active: boolean;
    is_delete: boolean;
}

export interface IUpdateAdvertisementDTO {
    nickname: string;
    gender: Gender;
    categories: string[];
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    daily_budget: number;
    click_cost: number;
    is_active: boolean;
    is_delete: boolean;
    create_model_list: ICreateAdvertisementModelDTO[];
    update_model_list: IUpdateAdvertisementModelDTO[];
}

const getAdvertisementModelAvatarFile = async (advertisement: IAdvertisement): Promise<File> => {
    const r = await fetch(`${ advertisement.avatar }?date=${Date.now()}`, { method: 'GET' });

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const blob = await r.blob();

    const avatarType = advertisement.avatar.includes('.')
        ? advertisement.avatar.split('.').pop()
        : blob.type.split('/').pop();

    const file = new File([blob], `${Date.now()}.${avatarType}`, { lastModified: Date.now(), type: blob.type });

    const avatar = await imageCompression(file, { maxSizeMB: 0.25 });
    
    return avatar;
}

const upAdvertisementModel = (nickname: string, model: IAdvertisementModel) => {
    if (!(model.create_moment instanceof Date)) {
        model.create_moment = new Date(model.create_moment);
    }
    if (!(model.update_moment instanceof Date)) {
        model.update_moment = new Date(model.update_moment);
    }

    if (!model.avatar) {
        model.avatar = `${server}/advertisement/model/avatar/${model.id}?data=${Date.now()}`;
    }

    if (!!model.apath) {
        model.destination_url = `https://onlyfans.com/${nickname}/${model.apath}`;
    }
    else if (!!model.trial) {
        model.destination_url = `https://onlyfans.com/action/trial/${model.trial}`;
    }
    else {
        model.destination_url = `https://onlyfans.com/${nickname}`;
    }
}

const upAdvertisement = (advertisement: IAdvertisement) => {
    if (!(advertisement.create_moment instanceof Date)) {
        advertisement.create_moment = new Date(advertisement.create_moment);
    }
    if (!(advertisement.update_moment instanceof Date)) {
        advertisement.update_moment = new Date(advertisement.update_moment);
    }
    advertisement.model_list.forEach((model) => upAdvertisementModel(advertisement.nickname, model));
}

export const upAdvertisementModelByCreate = async (advertisement: IAdvertisement): Promise<IAdvertisementModel> => {
    const model: IAdvertisementModel = {
        id: '',
        name: advertisement.name,
        about: advertisement.about,
        moderation_status: AdvertisementModelStatus.NONE,
        is_active: true,
        is_delete: false,
        visit_count: 0,
        visit_count_today: 0,
        view_count: 0,
        view_count_today: 0,
        click_count: 0,
        click_count_today: 0,
        spent_count: 0,
        spent_count_today: 0,
        create_moment: new Date(),
        update_moment: new Date(),
        avatar: advertisement.avatar,
        destination_url: '',
    };

    if (!!advertisement.avatar) {
        model.avatar = advertisement.avatar;
    }

    const avatarFile = await getAdvertisementModelAvatarFile(advertisement);
    model.avatar_file = avatarFile;

    upAdvertisementModel(advertisement.nickname, model);

    return model;
}

export const upAdvertisementModelByEdit = (nickname: string, model: IAdvertisementModel): IAdvertisementModel => {
    if (!model.destination_url) {
        throw new Error('InternalError: destination url is empty');
    }

    const r = { ...model };
    if (!!r.apath) {
        delete r['apath'];
    }
    if (!!r.trial) {
        delete r['trial'];
    }

    const zPath = `onlyfans.com/${nickname}`;
    const zIndex = model.destination_url.indexOf(zPath);
    if (zIndex + zPath.length == model.destination_url.length) {
        return r;
    }

    const fPath = `onlyfans.com/${nickname}`;
    const fIndex = model.destination_url.indexOf(fPath);
    if (0 <= fIndex && model.destination_url[fIndex + fPath.length] == '/') {
        const pathList = model.destination_url
            .slice(fIndex + fPath.length)
            .split('/')
            .filter((element) => element != '' && element != '/');

        if (pathList.join('/').length == 0) {
            r.destination_url = `https://onlyfans.com/${nickname}`;
            return r;
        }

        if (0 < pathList.length) {
            r.apath = pathList.join('/');
        }

        return r;
    }

    const sPath = `onlyfans.com/action/trial`;
    const sIndex = model.destination_url.indexOf(sPath);
    if (0 <= sIndex && model.destination_url[sIndex + sPath.length] == '/') {
        const pathList = model.destination_url
            .slice(sIndex + sPath.length)
            .split('/')
            .filter((element) => element != '' && element != '/');

        if (pathList.length != 1) {
            throw new Error(`
                Trial url is incorrect
                Url must be like:
                onlyfans.com/${nickname}
                onlyfans.com/${nickname}/[your additional url path here]
                onlyfans.com/action/trial/[your trial hash here]
            `);
        }
        if (pathList[0].length == 0) {
            throw new Error(`
                Trial hash is empty
                Url must be like:
                onlyfans.com/${nickname}
                onlyfans.com/${nickname}/[your additional url path here]
                onlyfans.com/action/trial/[your trial hash here]
            `);
        }

        r.trial = pathList[0];
        return r;
    }

    throw new Error(`
        Destination url is incorrect
        Url must be like:
        onlyfans.com/${nickname}
        onlyfans.com/${nickname}/[your additional url path here]
        onlyfans.com/action/trial/[your trial hash here]
    `);
}

export const createAdvertisement = async (dto: ICreateAdvertisementDTO, bear: string): Promise<IAdvertisement> => {
    const form = new FormData();
        
    Object.entries(dto)
        .filter((entry) => !['categories', 'create_model_list'].includes(entry[0]))
        .forEach((entry) => form.append(entry[0], entry[1]));

    form.append(
        'categories{}',
        JSON.stringify(dto.categories)
    );

    form.append(
        'create_model_list{}',
        JSON.stringify(dto.create_model_list.map((model) => {
            const m = { ...model };

            if (!!m.avatar) {
                delete m['avatar'];
            }

            return m;
        }))
    );
    dto.create_model_list.forEach((model, i) => {
        if (!!model.avatar) {
            form.append(`create_model_list.${i}.avatar`, model.avatar);
        }
    });

    const r = await fetch(
        `${server}/advertisement`,
        {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
            body: form,
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisement = await r.json() as IAdvertisement;

    upAdvertisement(advertisement);

    return advertisement;
}

export const getAdvertisementList = async (bear: string): Promise<IAdvertisement[]> => {
    const r = await fetch(
        `${server}/advertisement`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisementList: IAdvertisement[] = await r.json();

    advertisementList.forEach((advertisement) => upAdvertisement(advertisement));

    return advertisementList;
}

export const getAdvertisement = async (nickname: string, bear: string): Promise<IAdvertisement> => {
    const r = await fetch(
        `${server}/advertisement/${nickname}`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisement: IAdvertisement = await r.json();

    upAdvertisement(advertisement);

    return advertisement;
}

export const isAdvertisementExist = async (nickname: string, bear: string): Promise<boolean> => {
    const r = await fetch(
        `${server}/advertisement/${nickname}/exist`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const isExist: boolean = await r.json();

    return isExist;
}

export const updateAdvertisement = async (dto: IUpdateAdvertisementDTO, bear: string): Promise<IAdvertisement> => {
    const form = new FormData();

    Object.entries(dto)
        .filter((v) => v[0] != 'categories' && v[0] != 'create_model_list' && v[0] != 'update_model_list')
        .forEach((entry) => form.append(entry[0], entry[1]));
    
    form.append(
        'categories{}',
        JSON.stringify(dto.categories)
    );

    form.append(
        'create_model_list{}',
        JSON.stringify(dto.create_model_list.map((model) => {
            const m = { ...model };

            if (!!m.avatar) {
                delete m['avatar'];
            }

            return m;
        }))
    );
    dto.create_model_list.forEach((model, i) => {
        if (!!model.avatar) {
            form.append(`create_model_list.${i}.avatar`, model.avatar);
        }
    });

    form.append(
        'update_model_list{}',
        JSON.stringify(dto.update_model_list.map((model) => {
            const m = { ...model };

            if (!!m.avatar) {
                delete m['avatar'];
            }

            return m;
        }))
    );
    dto.update_model_list.forEach((model, i) => {
        if (!!model.avatar) {
            form.append(`update_model_list.${i}.avatar`, model.avatar);
        }
    });

    const r = await fetch(
        `${server}/advertisement`,
        {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
            body: form,
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisement: IAdvertisement = await r.json();

    upAdvertisement(advertisement);

    return advertisement;
}

export const refreshAdvertisement = async (nickname: string, bear: string) => {
    const r = await fetch(
        `${server}/advertisement/${nickname}/refresh`,
        {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisement: IAdvertisement = await r.json();

    upAdvertisement(advertisement);

    return advertisement;
}

export const deleteAdvertisement = async (nickname: string, bear: string) => {
    const r = await fetch(
        `${server}/advertisement/${nickname}`,
        {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const advertisement: IAdvertisement = await r.json();

    upAdvertisement(advertisement);

    return advertisement;
}

export const deleteAdvertisementModel = async (nickname: string, id: string, bear: string) => {
    const r = await fetch(
        `${server}/advertisement/${nickname}/model/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${bear}`,
            },
        }
    );

    if (!r.ok) {
        let message = r.statusText;
        
        try {
            const messageJson = await r.json();
            if (!!messageJson['message']) {
                message = messageJson['message'];
            }
        }
        catch {}

        throw new Error(message);
    }

    const model: IAdvertisementModel = await r.json();

    upAdvertisementModel(nickname, model);

    return model;
}
