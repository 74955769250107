import react, { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AdvertisementItemUpdateBody } from './AdvertisementItemUpdateBody';
import { AdvertisementItemUpdateTable } from './AdvertisementItemUpdateTable';
import { UpdateAdvertisementItemUpdateWindow } from './UpdateAdvertisementItemUpdateWindow';
import { CreateAdvertisementModelWindow } from '../../common/CreateAdvertisementModelWindow';
import { ReadAdvertisementModelWindow } from '../../common/ReadAdvertisementModelWindow';
import { UpdateAdvertisementModelWindow } from '../../common/UpdateAdvertisementModelWindow';
import { AuthContext } from '../../../../stor/AuthProvider';
import { AdvertisementModelStatus, getAdvertisement, IAdvertisement, IAdvertisementModel } from '../../../../api/advertisement';
import { AlertContext } from '../../../../stor/AlertProvider';
import { DeleteAdvertisementItemUpdateWindow } from './DeleteAdvertisementItemUpdateWindow';
import { ArchiveAdvertisementItemUpdateWindow } from './ArchiveAdvertisementItemUpdateWindow';
import { SwitchAdvertisementItemUpdateWindow } from './SwitchAdvertisementItemUpdateWindow';


interface IRequest {
    method: 'SUBMIT' | 'CREATE' | 'READ' | 'UPDATE' | 'SWITCH' | 'ARCHIVE' | 'DELETE';
    model?: IAdvertisementModel;
}

export const AdvertisementItemUpdate = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const [searchParams, _] = useSearchParams();

    const { user } = useContext(AuthContext);

    const counter = useRef<number>(0);

    const [prevAdvertisement, setPrevAdvertisement] = useState<IAdvertisement>(null);
    const [nextAdvertisement, setNextAdvertisement] = useState<IAdvertisement>(null);

    const [request, setRequest] = useState<IRequest>(null);

    useEffect(() => {
        const workEnterPress = (e) => {
            if (!prevAdvertisement || !nextAdvertisement || !!request || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();

            setRequest({ method: 'SUBMIT' });
        }

        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
        }
    }, [prevAdvertisement, nextAdvertisement, request]);

    useEffect(() => {
        const work = async () => {
            try {
                const nickname = searchParams.get('nickname');

                const a = await getAdvertisement(nickname, user.bear);

                setPrevAdvertisement({ ...a });
                setNextAdvertisement({ ...a });
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));
            }
        }

        work();
    }, []);

    return (
        <>
            <section className='advertisement-edit'>
                <div className='advertisement-edit__container'>
                    <h2 className='advertisement-edit__title title'>Update Advertisement</h2>
                    {
                        !!nextAdvertisement &&
                        <AdvertisementItemUpdateBody
                            advertisement={nextAdvertisement}
                            setAdvertisement={(a) => setNextAdvertisement(a)}
                        />
                    }
                    {
                        !!nextAdvertisement &&
                        <AdvertisementItemUpdateTable
                            item={nextAdvertisement}
                            onSubmitRequest={() => setRequest({ method: 'SUBMIT' })}
                            onCreateRequest={() => setRequest({ method: 'CREATE' })}
                            onReadRequest={(m) => setRequest({ method: 'READ', model: m })}
                            onUpdateRequest={(m) => setRequest({ method: 'UPDATE', model: m })}
                            onSwitchRequest={(m) => setRequest({ method: 'SWITCH', model: m })}
                            onArchiveRequest={(m) => setRequest({ method: 'ARCHIVE', model: m })}
                            onDeleteRequest={(m) => {
                                if (m.moderation_status == AdvertisementModelStatus.NONE) {
                                    setNextAdvertisement({
                                        ...nextAdvertisement,
                                        model_list: nextAdvertisement.model_list.filter((mm) => mm.id != m.id),
                                    });
                                }
                                else if (m.moderation_status == AdvertisementModelStatus.UNSAFE) {
                                    setNextAdvertisement({
                                        ...nextAdvertisement,
                                        model_list: nextAdvertisement.model_list.map((mm) => (mm.id != m.id) ? mm : { ...m, is_delete: true, update_moment: new Date() }),
                                    });
                                }
                                else {
                                    setRequest({ method: 'DELETE', model: m });
                                }
                            }}
                        />
                    }
                </div>
            </section>
            {
                !!request && request.method == 'SUBMIT' &&
                <UpdateAdvertisementItemUpdateWindow
                    prev={prevAdvertisement}
                    curr={nextAdvertisement}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'CREATE' &&
                <CreateAdvertisementModelWindow
                    advertisement={nextAdvertisement}
                    onCreate={(m) => {
                        counter.current += 1;

                        setNextAdvertisement({
                            ...nextAdvertisement,
                            model_list: [ { ...m, id: String(counter.current) }, ...nextAdvertisement.model_list ],
                        });

                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'READ' &&
                <ReadAdvertisementModelWindow
                    advertisement={nextAdvertisement}
                    model={request.model}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'UPDATE' &&
                <UpdateAdvertisementModelWindow
                    advertisement={nextAdvertisement}
                    model={request.model}
                    onUpdate={(m) => {
                        setNextAdvertisement({
                            ...nextAdvertisement,
                            model_list: nextAdvertisement.model_list.map((mm) => (mm.id != m.id) ? mm : { ...m, update_moment: new Date() }),
                        });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'SWITCH' &&
                <SwitchAdvertisementItemUpdateWindow
                    model={request.model}
                    onSwitch={(m) => {
                        setNextAdvertisement({
                            ...nextAdvertisement,
                            model_list: nextAdvertisement.model_list.map((mm) => (mm.id != m.id) ? mm : { ...m, is_active: !m.is_active, update_moment: new Date() }),
                        });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'ARCHIVE' &&
                <ArchiveAdvertisementItemUpdateWindow
                    model={request.model}
                    onArchive={(m) => {
                        setNextAdvertisement({
                            ...nextAdvertisement,
                            model_list: nextAdvertisement.model_list.map((mm) => (mm.id != m.id) ? mm : { ...m, is_delete: true, update_moment: new Date() }),
                        });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
            {
                !!request && request.method == 'DELETE' &&
                <DeleteAdvertisementItemUpdateWindow
                    nickname={nextAdvertisement.nickname}
                    model={request.model}
                    onDelete={(m) => {
                        setPrevAdvertisement({
                            ...prevAdvertisement,
                            model_list: prevAdvertisement.model_list.filter((mm) => mm.id != m.id),
                        });
                        setNextAdvertisement({
                            ...nextAdvertisement,
                            model_list: nextAdvertisement.model_list.filter((mm) => mm.id != m.id),
                        });
                        setRequest(null);
                    }}
                    onExit={() => setRequest(null)}
                />
            }
        </>
    );
}